import { database } from "src/config/fbConfig";
import firebase from "firebase/app";
import { AppDispatch, AppGetState, useAppDispatch } from "src/redux/store";
import { storage } from "src/config/fbConfig";
import {
  mapMarketOptions,
  mapLockerDistData,
  mapLockerLocData,
  mapMarketOrder,
} from "src/redux/reducers/marketSlice";
import { fetchMarketOptionSuccess } from "src/redux/reducers/topicSlice";
import {
  uploadMarketReceiptProgress,
  fetchMarketOrderSuccess,
} from "src/redux/reducers/marketOrderSlice";
import {
  TopicData,
  MarketData,
  MarketOrder,
  PhotoData,
  MarketSpec,
  marketOrderStatus,
  marketPaymentStatus,
  MarketPaymentMethod,
  marketDeliveryOption,
} from "src/types/typings";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";
import { fetchSingleTopicMarketThunk } from "src/redux/reducers/marketSlice";
import { getDynamicLink } from "src/components/topics/dynamicLink";
import { IoConstructOutline, IoLogoSnapchat } from "react-icons/io5";
/**
|--------------------------------------------------
| src/firebase/firebaseMarkets
|--------------------------------------------------
*/

export const checkMarketLastTS =
  (oldMarket: MarketData) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    const marketLastTS = await fetchMarketLastTS(
      oldMarket.topicID,
      oldMarket.marketID
    );
    if (marketLastTS !== null && oldMarket.marketLastTS !== marketLastTS) {
      // const market = cloneDeep(oldMarket);
      // topic.topicNeedsDownload = true;

      dispatch(
        fetchSingleTopicMarketThunk({
          topicID: oldMarket.topicID,
          marketID: oldMarket.marketID,
        })
      );
    }
  };

const fetchMarketLastTS = async (topicID: string, marketID: string) => {
  const ref = database.ref(
    `/topicMarkets/${topicID}/${marketID}/mktLastTimestamp`
  );
  const snap = (await ref.once("value")) as Record<string, any>;
  if (snap.exists()) {
    return snap.val() as number;
  } else {
    return null;
  }
};

export const fireFetchSingleTopicMarketDetails = async (
  topicID: string,
  marketID: string
) => {
  try {
    const ref = database.ref(`/topicMarkets/${topicID}/${marketID}`);
    const snap = await ref.once("value");
    if (snap.exists()) {
      return snap.val() as Record<string, any>;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const fireFetchMarketOrderQuota = async (
  topicID: string,
  marketID: string
) => {
  try {
    const ref = database.ref(
      `/topicMarkets/${topicID}/${marketID}/mktOrderLimit`
    );
    const snap = await ref.once("value");
    if (snap.exists()) {
      const val = snap.val() as number;
      return val;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const fireFetchSpecInventory = async (
  topicID: string,
  marketID: string,
  specID: string
) => {
  try {
    const ref = database.ref(
      `/topicMarkets/${topicID}/${marketID}/mktSpecs/${specID}`
    );
    const snap = await ref.once("value");
    if (snap.exists()) {
      const val = snap.val() as Record<string, any>;
      const specStock = val.specStock as number;
      return specStock;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const fireFetchTopicMarkets = async (topicID: string) => {
  try {
    const ref = database.ref(`/topicMarkets/${topicID}`);
    const snap = await ref.once("value");
    if (snap.exists()) {
      return snap.val() as Record<string, any>;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const fireFetchTopicMarketOptions =
  (topicID: string) => async (dispatch: AppDispatch) => {
    try {
      const ref = database.ref(`/topicMarketOptions/${topicID}`);
      const snap = await ref.once("value");
      if (snap.exists()) {
        const snapData = snap.val() as Record<string, any>;

        const topicMktOptions = mapMarketOptions(topicID, snapData);
        //topic.topicMarketOptions = topicMktOptions

        dispatch(fetchMarketOptionSuccess(topicMktOptions));
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

export const fireFetchTopicMarketOrder =
  (topicLinkID: string, topicID: string, orderID: string) =>
  async (dispatch: AppDispatch) => {
    try {
      const ref = database.ref(`/topicMarketOrders/${topicID}/${orderID}`);
      const snap = await ref.once("value");
      if (snap.exists()) {
        const snapData = snap.val() as Record<string, any>;
        const topicMktOrder = mapMarketOrder(
          topicID,
          topicLinkID,
          orderID,
          snapData
        );
        dispatch(fetchMarketOrderSuccess(topicMktOrder));
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

export const fireFetchAllTopicMarketOrders =
  (topicID: string, topicLinkID: string) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      //const user = firebase.auth().currentUser;
      const user = getState().auth.authUser;
      if (!user || !user.userID) {
        return null;
      }

      const ref = database.ref(`/users/${user.userID}/userBuyerMarkets/orders`);
      const query = ref.orderByChild("topicID").equalTo(topicID);
      const snap = await query.once("value");

      if (snap.exists()) {
        const snapData = snap.val() as Record<string, any>;

        const orderIDs = Object.keys(snapData);

        orderIDs.map((orderID) => {
          dispatch(fireFetchTopicMarketOrder(topicLinkID, topicID, orderID));
        });
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

export const fireFetchLockerDist = async (lockerArea: string) => {
  try {
    const ref = database.ref(`/efLockerDist/hk/${lockerArea}`);
    const snap = await ref.once("value");
    if (snap.exists()) {
      const snapData = snap.val() as Record<string, any>;

      const lockDistIDs = Object.keys(snapData);
      const lockDistData = lockDistIDs.map((lockDistID) => {
        const snap = snapData[lockDistID];
        return mapLockerDistData(lockDistID, snap);
      });

      return lockDistData;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const fireFetchLockerLoc = async (lockerCode: string) => {
  try {
    const ref = database.ref(`/efLockers/hk/${lockerCode}`);
    const snap = await ref.once("value");
    if (snap.exists()) {
      const snapData = snap.val() as Record<string, any>;
      return mapLockerLocData(lockerCode, snapData);
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const fetchSellerDLink =
  (mktOrder: MarketOrder) => async (dispatch: AppDispatch) => {
    if (!mktOrder.orderSellerID) {
      return;
    }
    const sellerDLinkRef = database.ref(
      `/dLink/userContact/${mktOrder.orderSellerID}/dURL`
    );
    try {
      const snap = await sellerDLinkRef.once("value");
      if (snap.exists()) {
        const dURL = snap.val() as string;
        const tmpMktOrder: Partial<MarketOrder> = {};
        tmpMktOrder.orderID = mktOrder.orderID;
        tmpMktOrder.orderSellerDLink = dURL;
        dispatch(fetchMarketOrderSuccess(tmpMktOrder));
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

export const uploadMarketDLink =
  (
    dLink: string,
    topicLinkID: string,
    topicID: string,
    orderID: string,
    email: string,
    buyerID: string,
    sellerID: string
  ) =>
  async (dispatch: AppDispatch) => {
    if (!dLink || dLink.length === 0) {
      return;
    }
    const marketOrderRef = database.ref(
      `/topicMarketOrders/${topicID}/${orderID}`
    );
    const dLinkRef = database.ref(`/dLink/mktOrders/${topicLinkID}/${orderID}`);
    await marketOrderRef.update({
      orderDLink: dLink,
      isWeb: true,
    });

    await dLinkRef.set({
      dURL: dLink,
      email: email,
      buyerID: buyerID,
      sellerID: sellerID,
      topicID: topicID,
    });
  };

//PHOTO UPLOADED
export const uploadMarketReceipt =
  (topicID: string, file: any, orderID: string, payment: MarketPaymentMethod) =>
  async (dispatch: AppDispatch) => {
    const user = firebase.auth().currentUser;
    if (!user) {
      return;
    }
    const userID = user.uid;

    const itemRef = database.ref(`/topicPhotos/${topicID}`).push();
    const itemKey = itemRef.key;

    if (!itemKey) {
      return;
    }

    const uploadPath = `MarketOrder/${topicID}/${userID}/${itemKey}`;
    const uploadItem = storage.ref(uploadPath);
    const uploadTask = uploadItem.put(file);
    uploadTask.on(
      "state_changed",
      function progress(snapshot) {
        const pct = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        const paymentID = payment.paymentID;
        const payload = { paymentID: paymentID, pct: pct };
        dispatch(uploadMarketReceiptProgress(payload));
      },
      function error(err) {
        console.log(err);
      },
      async function complete() {
        const metaData = await uploadItem.getMetadata();
        const downloadURL = await uploadItem.getDownloadURL();

        //uploadItem.getMetadata().then(function(metaData) {
        //uploadItem.getDownloadURL().then(function(downloadURL) {

        const ts = Date.parse(metaData.timeCreated);
        const size = metaData.size;

        const marketOrderRef = database.ref(
          `/topicMarketOrders/${topicID}/${orderID}`
        );

        //firebase
        const remotePhotoData = {
          photoCreationTimestamp: ts,
          photoCreatorID: userID,
          photoUrl: downloadURL,
          photoContentSize: size,
        };

        const photoRef = marketOrderRef.child("orderReceipt").child(itemKey);
        const paymentMethodRef = marketOrderRef
          .child("orderPaymentMethod")
          .child(payment.paymentID);

        await photoRef.set(remotePhotoData);

        //local
        const localPhotoData: Partial<PhotoData> = {
          photoID: itemKey,
          photoCreatorID: userID,
          photoContentURL: downloadURL,
          photoCreationTimestamp: ts,
          photoContentSize: size,
        };

        const newPaymentStatus = parseInt(marketPaymentStatus.PAID.toString());

        const newData = {
          orderPaymentPhoto: itemKey, //Tag the payment photo, note we allow multiple uploads, but only one will be shown
          orderLastTimestamp: ts,
          orderPaymentID: payment.paymentID, //Tag the payment method, note we allow multiple uploads, but only one will be shown
          orderPaymentTimestamp: ts,
          orderPaymentStatus: newPaymentStatus,
        };

        const paymentMethodItem = {
          paymentTitle: payment.paymentTitle,
          paymentLink: payment.paymentLink,
        };

        await marketOrderRef.update(newData);
        await paymentMethodRef.update(paymentMethodItem);

        if (
          payment.paymentPhoto.photoID &&
          payment.paymentPhoto.photoContentURL !== ""
        ) {
          //Have a payment method photo
          const paymentMethodPhotoItem = {
            photoCreationTimestamp: payment.paymentPhoto.photoCreationTimestamp,
            photoCreatorID: payment.paymentPhoto.photoCreatorID,
            photoContentSize: payment.paymentPhoto.photoContentSize,
            photoUrl: payment.paymentPhoto.photoContentURL,
          };
          await paymentMethodRef
            .child("paymentPhoto")
            .child(payment.paymentPhoto.photoID)
            .set(paymentMethodPhotoItem);
        }

        let modifiedMarketOrder = {
          orderID: orderID,
          orderPaymentTS: ts,
          orderPaymentMethod: payment,
          orderPaymentStatus: marketPaymentStatus.PAID,
          orderPaymentReceiptPhoto: localPhotoData,
        };

        dispatch(fetchMarketOrderSuccess(modifiedMarketOrder));
      }
    );
  };

// let marketOrderRef: DatabaseReference = GlobalFirebase.ref.child("topicMarketOrders").child(marketOrder.topicID).child(marketOrder.orderID)

//         let intTimestamp = FirebaseDateTransform().getFirebaseTimestampInt()

//         if marketOrder.orderPaymentReceipt.photoContent != "" {
//             marketOrderRef.updateChildValues(["orderPaymentPhoto": marketOrder.orderPaymentReceipt.photoID,
//"orderPaymentPhotoTS": intTimestamp,
//"orderLastTimestamp": intTimestamp,
//"orderPaymentID": marketOrder.orderPaymentID,
//"orderPaymentTimestamp": intTimestamp,
//"orderPaymentStatus": newStatus.rawValue], withCompletionBlock: {(err, ref) in
//                 if err != nil {
//                     completion(false, intTimestamp)
//                 } else {
//                     completion(true, intTimestamp)
//                 }
//             })
//         }

export const uploadMarketOrder = async (
  marketOrderData: Partial<MarketOrder>,
  topicTitle: string
) => {
  const user = firebase.auth().currentUser;
  if (!user) {
    return;
  }
  const userID = user.uid;

  const topicID = marketOrderData.topicID;
  const topicLinkID = marketOrderData.topicLinkID;
  if (!topicID || !topicLinkID) {
    return;
  }

  const marketOrderRef = database.ref(`/topicMarketOrders/${topicID}`).push();
  const orderID = marketOrderRef.key;
  if (!orderID) {
    return;
  }

  let orderDeliveryMethod = 0;
  if (marketOrderData.orderDeliveryMethod) {
    const orderDeliveryMethodTmp =
      marketOrderData.orderDeliveryMethod.toString();
    orderDeliveryMethod = parseInt(orderDeliveryMethodTmp);
  }

  const orderUID = await generateOrderUID(orderID, topicID);
  const orderUIDInt = parseInt(orderUID);

  //TODO: Error handling
  if (!orderUID || orderUID == "") {
    return;
  }

  const title = `Order #${orderUID}`;
  const desc = `Order for ${topicTitle}`;

  const dLink = await getDynamicLink(topicLinkID, orderID, title, desc);
  if (!dLink || dLink == "") {
    return;
  }

  const dLinkRef = database.ref(`/dLink/mktOrders/${topicLinkID}/${orderID}`);

  const orderItem = {
    orderUserID: userID,
    orderUID: orderUIDInt,
    orderSellerID: marketOrderData.orderSellerID,
    orderName: marketOrderData.orderName,
    orderAddress1: marketOrderData.orderAddress1,
    orderAddress2: marketOrderData.orderAddress2,
    orderPhone: marketOrderData.orderPhone,
    orderEmail: marketOrderData.orderEmail,
    orderEFLockerCode: marketOrderData.orderEFLockerCode,
    orderCurrency: marketOrderData.orderCurrency,
    orderDeliveryMethod: orderDeliveryMethod,
    orderPickup: marketOrderData.orderPickupDetails,
    orderMsg: marketOrderData.orderAdditionalMsg,
    orderPaymentStatus: marketOrderData.orderPaymentStatus,
    orderStatus: marketOrderData.orderStatus,
    orderPrice: marketOrderData.orderProductPrice,
    orderShipPrice: marketOrderData.orderShippingFee,
    orderFinalPrice: marketOrderData.orderFinalPrice,
    orderTimestamp: firebase.database.ServerValue.TIMESTAMP,
    orderLastTimestamp: firebase.database.ServerValue.TIMESTAMP,
    orderCourierPayment: marketOrderData.orderCourierPayment,
    orderDLink: dLink,
    isWeb: true,
  };

  //This triggers firebase function "sendNewMarketOrderNotification"
  await marketOrderRef.set(orderItem);
  await dLinkRef.set({
    dURL: dLink,
    email: marketOrderData.orderEmail,
    buyerID: userID,
    sellerID: marketOrderData.orderSellerID,
    topicID: topicID,
  });

  const orderSpecs = marketOrderData.orderSpecs;

  if (orderSpecs) {
    orderSpecs.map(async (orderSpec) => {
      const specRef = marketOrderRef.child(`/orderSpecs`).push();

      const specOrderItem = {
        specID: orderSpec.specID,
        specDesc: orderSpec.specDesc,
        specPrice: orderSpec.specPrice,
        specCurrency: orderSpec.specCurrency,
        specQuantity: orderSpec.specQuantity,
        specStock: orderSpec.specStock,
        specSKU: orderSpec.specSKU,
        specMktTitle: orderSpec.specMktTitle,
        specMktID: orderSpec.specMktID,
        specOrderLimit: orderSpec.specOrderLimit,
      };

      specRef.set(specOrderItem);

      //Photo
      if (orderSpec.specPhoto) {
        const specPhoto = orderSpec.specPhoto;
        if (specPhoto.photoID && specPhoto.photoContentURL != "") {
          const specPhotoItem = {
            photoCreationTimestamp: firebase.database.ServerValue.TIMESTAMP,
            photoCreatorID: userID,
            photoCaption: specPhoto.photoCaption ?? "",
            photoContentSize: specPhoto.photoContentSize ?? 0,
            photoUrl: specPhoto.photoContentURL,
          };

          const specPhotoRef = specRef
            .child("specPhoto")
            .child(specPhoto.photoID);
          specPhotoRef.update(specPhotoItem);
          specRef.child("specPhotoID").set(specPhoto.photoID);
        }
      } else {
        orderSpec.specMktID;
      }

      //specT2IDs
      if (orderSpec.specT2IDs) {
        orderSpec.specT2IDs.map((specT2ID) => {
          specRef.child("specT2s").child(specT2ID).set(true);
        });
      }
    });
  }

  if (marketOrderData.marketIDs) {
    marketOrderData.marketIDs.map((mktID) => {
      marketOrderRef.child("orderMktIDs").child(mktID).set(true);
    });
  }

  //Put together a new marketOrderData
  let newMarketOrder: Partial<MarketOrder> = marketOrderData;
  newMarketOrder.orderUID = orderUID;
  newMarketOrder.orderUserID = userID;
  newMarketOrder.orderID = orderID;

  return newMarketOrder;
};

export const generateOrderUID = async (orderID: string, topicID: string) => {
  try {
    const ref = database.ref(`/orderUIDMax`);
    const snap = await ref.once("value");
    if (snap.exists()) {
      const orderMaxUID = snap.val() as number;
      const codeInt = orderMaxUID + Math.floor(Math.random() * 1000);

      const orderUIDItem = {
        orderUID: codeInt,
        topicID: topicID,
      };

      await database.ref(`marketOrderUID/${orderID}`).set(orderUIDItem);

      return codeInt.toString();
    }
  } catch (error) {
    return error;
  }
};
