/**
|--------------------------------------------------
| src/types/typings
|--------------------------------------------------
*/

export type MarketData = {
  marketID: string;
  marketTitle: string;
  marketDesc: string;
  topicID: string;
  marketCurrency: string;
  marketCategoryT1: string;
  marketCategoryT2: string;
  marketDisplayPrice: string;
  marketDisplayOriPrice: string;
  marketOrderLimit: number;
  marketCondition: number;
  marketCreationTS: number;
  marketLastTS: number;
  marketOrder: number;
  marketPhotos: Record<string, any>;
  marketSpecs: Record<string, any>;
  marketSpecT1: Record<string, any>; //This is the record retrieved from Firebase
  marketTags: Record<string, string>;
  marketDisplayPhotoURL: string;
  marketDisplayPhoto: Partial<PhotoData>; //Used in mapSpecOrder
  marketSpecT1sCln: MarketSpecT1[]; //This is the cleaned specT1s data
  //marketSpecsCln: Partial<MarketSpec>[]; //We use reselect to select out the market specs, so this is not needed
};

export type MarketSpec = {
  specID: string;
  specDesc: string;
  specPrice: number;
  specOriPrice: number;
  specTopicID: string;
  specStock: number;
  specSKU: string | undefined;
  specPhoto: Partial<PhotoData>;
  specMarketID: string;
  isCart: boolean;
  specOrderTS: number;
  specOrderLimit: number;
  orderQuantity: number;
  specT2IDs: string[];
  specMarket: Partial<MarketData>;
};

//Save into seprate order spec after ordering, so the changes in marketSpec wouldn't affect the order shown
export type MarketOrderSpec = {
  specOrderID: string; //
  orderID: string; //
  specID: string; //
  specMktTitle: string; //
  specMktID: string; //
  specDesc: string; //
  specPrice: number; //
  specCurrency: string; //
  specTopicID: string; //
  specStock: number; //
  specSKU: string | undefined;
  specPhoto: Partial<PhotoData>; //
  specOrderLimit: number; //
  specQuantity: number; //
  specT2IDs: string[]; //
};

//NOTES: to upload enum, we have to convert to number first, otherwise it's a string!
export type MarketOrder = {
  topicID: string; //
  topicLinkID: string; //
  orderID: string; //
  orderUserID: string; //buyerID
  orderSellerID: string; //sellerID
  orderUID: string; //
  orderName: string; //
  orderAddress1: string; //
  orderAddress2: string; //
  orderPhone: string; //
  orderEmail: string; //
  orderEFLockerCode: string; //
  orderCourierPayment: string; //
  orderCurrency: string; //
  orderDeliveryMethod: marketDeliveryOption; //
  orderPickupDetails: string; //
  orderAdditionalMsg: string; //
  orderStatus: marketOrderStatus; //
  orderTS: number; //
  orderLastTS: number; //
  orderProductPrice: number; //
  orderShippingFee: number; //
  orderFinalPrice: number; //
  isOrderPlaced: boolean; //
  orderPaymentTS: number; //
  orderPaymentStatus: marketPaymentStatus; //
  orderPaymentMethod: MarketPaymentMethod; //
  orderPaymentReceiptPhoto: Partial<PhotoData>; //
  orderCourierCompany: string; //
  orderTrackingNum: string; //
  orderSpecs: MarketOrderSpec[]; //
  marketIDs: string[]; //
  orderDLink: string;
  orderSellerDLink: string; //The dynamic link to contact seller for our app
};

export type MarketOptions = {
  topicID: string;
  mktShipping: marketShippingFeeOption;
  mktDeliveryOption: marketDeliveryOption;
  mktShippingFee: number;
  mktMinFreeShippingFee: number;
  mktExpiryTimestamp: number;
  mktOptCreationTimestamp: number;
  mktOptLastTimestamp: number;
  mktIsName: boolean;
  mktIsAddress: boolean;
  mktIsPhone: boolean;
  mktIsEmail: boolean;
  mktIsEFLocker: boolean;
  mktPaymentDetails: string;
  mktContactDetails: string;
  mktPickupLocs: Record<string, string>;
  mktPayments: MarketPaymentMethod[];
  mktContacts: MarketContactMethod[];
};

export type MarketContactMethod = {
  contactID: string;
  contactTitle: string;
  contactPhoto: Partial<PhotoData>;
  contactLink: string;
};

export type MarketPaymentMethod = {
  paymentID: string;
  paymentTitle: string;
  paymentPhoto: Partial<PhotoData>;
  paymentLink: string;
};

export type MarketSpecT1 = {
  specT1ID: string;
  specTitle: string;
  specT2s: MarketSpecT2[];
};

export type MarketSpecT2 = {
  specT2ID: string;
  specT1ID: string;
  specTitle: string; //Same as T1 title
  specDetail: string;
};

export type MarketState = {
  data: Record<string, MarketData>;
  isLoading: boolean;
  currentRequestId: string | undefined;
  error: string | null;
};

export type MarketSpecState = {
  data: Record<string, MarketSpec>;
  isLoading: boolean;
  currentRequestId: string | undefined;
  error: string | null;
};

export type MarketOrderState = {
  data: Record<string, MarketOrder>;
  isLoading: boolean;
  currentRequestId: string | undefined;
  error: string | null;
  progress: Record<string, number>; //<paymentMethodID, progress>
};

export type TopicData = {
  topicID: string;
  topicFollow: boolean;
  topicNotify: boolean;
  topicSealed: boolean;
  topicLastTimestamp: number;
  topicNeedsDownload: boolean;
  topicIsUserTopic: boolean;
  topicContent: string;
  topicTitle: string;
  topicCreatorID: string;
  topicCreatorName: string;
  topicRichContent: string;
  topicCreationTimestamp: number;
  topicModifyTimestamp: number;
  topicLastMessage: string;
  topicPhotosCount: number;
  topicVideosCount: number;
  topicGroups: Record<string, boolean>;
  topicDM: boolean;
  topicDMRecipientID: string;
  topicGroupNames: string;
  topicAvatars: string[];
  topicCreatorAvatar: string;
  topicHeaderPath: string;
  topicIsObservingChange: boolean;
  topicMarketsCount: number;
  topicLinkID: string; //Denote the id used in web address
  topicMarketOptions: Partial<MarketOptions>;
};

export type TopicState = {
  data: Record<string, TopicData>;
  isLoading: boolean;
  error: string | null;
};

export type UserData = {
  userID: string;
  name: string;
  email: string;
  backgroundCoverLink: string;
  profilePicLink: string;
};

export type UserState = {
  data: Record<string, UserData>;
  isLoading: boolean;
  currentRequestId: string | undefined;
  error: string | null;
};

export type GroupData = {
  groupID: string;
  groupName: string;
  groupAvatar: string;
  groupBackground: string;
  groupCreationTimestamp: number;
  groupDetails: string;
  groupMembersCount: number;
  groupLinksCount: number;
  groupPrivacy: string;
  groupUserDefinedUID: string;
  groupJoinStatus: groupJoinStatus;
  groupJoinTimestamp: number;
  groupQuitTimestamp: number;
  groupTopicsFollow: boolean;
  groupTopicsNotify: boolean;
  groupEarliestLoadedTopicModifyTimestamp: number;
};

export type NotificationData = {
  notiID: string;
  notiAvatar: string;
  notiContent: string;
  notiIsRead: boolean;
  notiGroupID: string;
  notiRequestGroupID: string;
  notiTopicID: string;
  notiUserID: string;
  notiTimestamp: number;
  notiType: notiType;
};

export type MsgData = {
  msgID: string;
  msgContent: string;
  msgTimestamp: number;
  msgSenderID: string;
  //msgSenderAvatar: string;
  msgSenderName: string;
  msgTopicID: string;
  msgType: msgType;
  msgURL?: string;
  msgPhotoID?: string;
  msgVideoID?: string;
  msgAudioID?: string;
  msgFileID?: string;
  msgReplyID?: string;
  msgReplyDownloadTimestamp?: number;
  msgDeleted?: boolean;
  msgDeletedTimestamp?: number;
  msgAvatar?: string;
  msgIsUploaded?: boolean;
};

export type PhotoData = {
  photoID: string;
  photoCreatorID: string;
  photoCreationTimestamp: number;
  photoCaption: string;
  photoContentURL: string;
  photoThumbnailURL: string;
  photoContentSize: number;
  photoIsDownloaded: boolean;
  photoIsTopicContent: boolean;
  photoOrder: number;
  photoMarketID: string;
  photoSpecID: string;
};

export type LockerDistData = {
  distID: string;
  distLocs: Record<string, number>;
  distName: string;
  distNameCT: string;
  isStore: boolean;
  lastTS: number;
};

export type LockerLocData = {
  locID: string;
  address: string;
  addressCT: string;
  area: string;
  districtCT: string;
  district: string;
  districtid: string;
  hourmo2st: string;
  hoursu: string;
  isHot: boolean;
  isStore: boolean;
  lastTS: number;
};

export type TaskData = {
  taskID: string;
  taskTitle: string;
};

export type AuthState = {
  authUser: Partial<UserData>;
  isLoggedIn: boolean;
  error: string | null;
};

export type GroupState = {
  data: Record<string, GroupData>;
  isLoading: boolean;
  currentRequestId: string | undefined;
  error: string | null;
};

export type MsgState = {
  data: Record<string, MsgData>;
  isLoading: boolean;
  currentRequestId: string | undefined;
  error: string | null;
};

export type PhotoState = {
  data: Record<string, PhotoData>;
  isLoading: boolean;
  currentRequestId: string | undefined;
  error: string | null;
};

export interface FormFields {
  orderName: string;
  orderEmail: string;
  orderAdditionalMsg: string;
  orderAddress1: string;
  orderAddress2: string;
  orderPhone: string;
  orderEFLockerCode: string;
  orderCourierPayment: string;
  orderShippingAddress: number; //0-undefined, 1-Locker, 2-Address
  orderShippingFee: number;
  orderDeliveryMethod: marketDeliveryOption;
  orderLockerArea: lockerArea;
  orderDistID: string;
  orderLockerType: number; //0-undefined, 1-EFLocker, 2-EFStore
  orderPickupDetails: string;
  orderLockerLocID: string;
  orderLockerDists: Partial<LockerDistData>[];
  selOrderLockerDist: Partial<LockerDistData>;
  orderLockerLocs: Partial<LockerLocData>[];
  orderCourierPaymentTxt: string;
  orderProductPrice: number;
  orderFinalPrice: number;
  orderCur: string;
  orderMktIDs: string[];
  orderSpecs: MarketOrderSpec[];
}

//Enums
//JS treats false || undefined || null || 0 || "" || '' || NaN all as the same value when compared using ==
export enum langs {
  "TC" = 0,
  "EN",
}

export enum marketOrderStatus {
  "SELLERUNCONFIRMED" = 0,
  "SELLERCONFIRMED",
  "CANCELLED",
  "SHIPPED",
  "DELIVERED",
}

export enum marketPaymentStatus {
  "UNPAID" = 0,
  "PAID",
  "MARKEDASPAID",
  "REFUNDED",
}

export enum groupJoinStatus {
  "UNJOINED" = 1,
  "JOINED",
  "PENDING",
  "INVITED",
  "OWNLEFT",
  "REMOVED",
}

export enum marketShippingFeeOption {
  "NOTSET" = 0,
  "ONDELIVERY",
  "FREE",
  "FIXED",
}

export enum marketDeliveryOption {
  "NOTSET" = 0,
  "COURIER",
  "PICKUP",
  "BOTH",
}

export enum lockerArea {
  "NOTSET" = 0,
  "HK",
  "KLN",
  "NT",
}

export enum notiType {
  "USERRECEIVEINITE" = 1,
  "PREAPPROVEDUSERJOINEDGROUPS",
  "GROUPADMINPENDINGLINKAPPROVAL",
  "GROUPADMINPENDINGMEMBERAPPROVAL",
  "USERREMOVEDFROMGROUP",
  "TOPICBAN",
  "USERRECEIVETASKASSIGNMENT",
}

export enum msgType {
  "PHOTO" = 1,
  "TEXT",
  "LOCATION",
  "VIDEO",
  "AUDIO",
  "FILE",
}
