import firebase from "firebase/app";
//import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/storage'
//kalink-3b0d7.firebaseapp.com
//for authDoamin refer to: https://stackoverflow.com/questions/41736460/exception-this-browser-is-not-supported-or-3rd-party-cookies-and-data-may-be-di
let firebaseConfig = {
    apiKey: "AIzaSyDo2CCm0FzarbUz6mjMfJwJkAi12VNudBs",
    authDomain: "simply-link.firebaseapp.com",
    databaseURL: "https://simply-link-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "simply-link",
    storageBucket: "simply-link.appspot.com",
    messagingSenderId: "167541197999",
    appId: "1:167541197999:web:37a67dac9d5158a35c185a",
    measurementId: "G-RPFQPCWRDP"
  };

// if (window.location.hostname === 'localhost') {
//   firebaseConfig = {
//     host: "losthost:5000",
//     databaseURL: 'http://localhost:9000?ns=kalink-3b0d7'
//   }
// }


const app = firebase.initializeApp(firebaseConfig);

//uncomment for emulator
if (window.location.hostname === "localhost") {
  firebase.functions().useFunctionsEmulator("http://localhost:5001");
  app.auth().useEmulator("http://localhost:9099");
  firebase.database().useEmulator("localhost", 9000)
  firebase.storage().useEmulator("localhost", 9199)
  //firebase.auth().useEmulator("http://localhost:9099")
}



//export const topicsRef = database.child("topics");
export default app;
export const database = firebase.database()
export const storage = firebase.storage()
export { firebaseConfig }
