import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { fetchTopicThunk } from "../../redux/utils/topicUtils";
import moment from "moment";
import NotFound from "src/components/notFound";
import { Helmet } from "react-helmet-async";
import {
  BrowserRouter,
  useParams,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";
import queryString from "query-string";
import styles from "src/marketStyle.module.scss";
import {
  MarketCartPlaceholder,
  TopicHeader,
  UserAvatarPlaceholder,
} from "./holderImg";
import {
  Container,
  Image,
  Card,
  Col,
  Row,
  Button,
  Badge,
  CardDeck,
  CardColumns,
} from "react-bootstrap";
import LoadingScreen from "src/components/loading";
import {
  fetchSingleTopicFromTopicLink,
  fetchTopicLinkFromTopicID,
  checkTopicLastTS,
} from "src/firebase/firebaseTopics";
import { fireFetchTopicMarketOptions } from "src/firebase/firebaseMarkets";
import {
  fetchTopicMarketsThunk,
  marketSlice,
} from "src/redux/reducers/marketSlice";
import { useAppDispatch } from "src/redux/store";
import { TopicData, MarketData, MarketOrder } from "src/types/typings";
import { AppState } from "src/redux/store";
import {
  selectTopic,
  selectTopicWithTopicID,
} from "src/selectors/topicSelector";
import {
  selectTopicMarkets,
  selectTopicMarketTags,
  selectTopicMarketOrders,
} from "src/selectors/marketSelector";
import { useSelector } from "react-redux";
import _ from "lodash";
import ShowNavBar from "src/components/navBar";
import { fireFetchAllTopicMarketOrders } from "src/firebase/firebaseMarkets";
import ReactHtmlParser from "react-html-parser";
import TopicMarket from "./topicMarket";

//"destination": "/index.html"

export const MakeSelectWithTopicLinkID = ({ topicLinkID }) => {
  const topic: TopicData | undefined = useSelector((state: AppState) =>
    selectTopic(state, topicLinkID)
  );
  return topic;
};

export const MakeSelectMarkets = ({ topicLinkID }) => {
  const markets: MarketData[] | undefined = useSelector((state: AppState) =>
    selectTopicMarkets(state, topicLinkID)
  );
  return markets;
};

export const MakeSelectTopicMarketTags = ({ topicID }) => {
  const topicMarketTags: string[] | undefined = useSelector((state: AppState) =>
    selectTopicMarketTags(state, topicID)
  );

  return topicMarketTags;
};

export const MakeSelectMarketOrders = ({ topicLinkID }) => {
  const marketOrders: MarketOrder[] | undefined = useSelector(
    (state: AppState) => {
      return selectTopicMarketOrders(state, topicLinkID);
    }
  );

  return marketOrders;
};

//SECTION: MAIN FUNCTION
const TopicDetails = () => {
  console.log("TopicDetails called");
  const [isLoading, setIsLoading] = useState(false);
  const [topicLinkID, setTopicLinkID] = useState("");

  const appDispatch = useAppDispatch();
  const { id } = useParams(); //!Note: the id here is topicLink/id, it's not the real topicID

  const location = useLocation();

  //To hide the navbar for some in app pages (terms & condition/privacy policy for now)
  const queryStr = location.search;
  const values = queryString.parse(queryStr);
  const isNavStr = (values.nav as string) ?? "1";
  const isNav = isNavStr == "1";

  // const replaceTopicContent = useRef(null);
  // const replaceTimestamp = useRef(null);

  const topic = MakeSelectWithTopicLinkID({
    topicLinkID: id,
  });
  const markets = MakeSelectMarkets({
    topicLinkID: id,
  });
  const marketOrders = MakeSelectMarketOrders({
    topicLinkID: id,
  });

  const isMarket = !(markets === undefined);
  const isMarketOrder = !(marketOrders === undefined);

  //Make sure the topic is up to date
  useEffect(() => {
    console.log("running useEffect1");
    if (topic) {
      appDispatch(checkTopicLastTS(topic));
    }
  }, []);

  useEffect(() => {
    if (!topic || (topic && topic.topicNeedsDownload)) {
      console.log("re-fetching topic", topic?.topicLastTimestamp);
      setIsLoading(true);
      appDispatch(fetchSingleTopicFromTopicLink(id));

      if (topic && topic.topicNeedsDownload) {
        //If there are changes to market, topicNeedsDownload will be marked as true
        setIsLoading(true);
        appDispatch(fetchTopicMarketsThunk(topic.topicID));
        appDispatch(fireFetchTopicMarketOptions(topic.topicID));
        appDispatch(
          //This is for navBar orders
          fireFetchAllTopicMarketOrders(topic.topicID, topic.topicLinkID)
        );
      }
    } else {
      if (
        topic &&
        topic.topicMarketsCount > 0 &&
        (!markets || markets.length == 0)
      ) {
        setIsLoading(true);
        appDispatch(fetchTopicMarketsThunk(topic.topicID));
        appDispatch(fireFetchTopicMarketOptions(topic.topicID));
        appDispatch(
          //This is for navBar orders
          fireFetchAllTopicMarketOrders(topic.topicID, topic.topicLinkID)
        );
      } else {
        setIsLoading(false);
      }
    }

    // if (topic && topic.topicMarketsCount > 0) {
    //
    //   if (!markets) {
    //     setIsLoading(true);
    //     appDispatch(fetchTopicMarketsThunk(topic.topicID));
    //   }
    //   appDispatch(fireFetchTopicMarketOptions(topic.topicID));
    //   appDispatch(
    //     fireFetchAllTopicMarketOrders(topic.topicID, topic.topicLinkID)
    //   );
    // }
  }, [setIsLoading, markets, topic, id]);

  // useEffect(() => {
  //

  // }, [markets, setIsLoading]);

  if (topic && topic !== undefined) {
    return (
      <>
        <Helmet>
          <title> {topic.topicTitle} </title>
          <meta property="og:title" content={topic.topicTitle} />
          <meta property="og:image" content={topic.topicHeaderPath} />
          <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
        </Helmet>
        <ShowNavBar
          topicLinkID={topic.topicLinkID}
          isMarket={isMarket}
          isMarketOrder={isMarketOrder}
          activeKey="home"
          isShow={isNav}
        />
        <div className="topicHeader">
          <TopicHeader url={topic.topicHeaderPath} />
        </div>
        <Container className="p-0 pb-5 d-flex flex-row justify-content-center noScrollX">
          <div className="w-100">
            <div className="avatar">
              <div className="avatar-image">
                <UserAvatarPlaceholder
                  url={topic.topicCreatorAvatar}
                  thumbnail={false}
                  roundedCircle={true}
                />
              </div>
              <div className="avatar-name">{topic.topicCreatorName}</div>
              <div className="avatar-timestamp">
                {moment(topic.topicCreationTimestamp).format("LLL")}
              </div>
            </div>
            <span>
              <h1 className="title-font">{topic.topicTitle}</h1>
            </span>
            <p className="p-2">{ReactHtmlParser(topic.topicContent)}</p>
            <MarketDetails
              markets={markets}
              topicLinkID={topic.topicLinkID}
              topicID={topic.topicID}
            />
          </div>
        </Container>
      </>
    );
  } else if (isLoading) {
    return <LoadingScreen />;
  } else {
    return (
      <>
        <NotFound />
      </>
    );
  }
};

export default TopicDetails;

const MarketDetails = (props: {
  markets: MarketData[] | undefined;
  topicLinkID: string;
  topicID: string;
}) => {
  const markets = props.markets;
  const topicID = props.topicID;

  const topicLinkID = props.topicLinkID;

  const mktTags = MakeSelectTopicMarketTags({ topicID: topicID });
  const [selMktTags, setSelMktTags] = useState([] as string[]);

  useEffect(() => {
    //
  }, [selMktTags]);

  const ShowAllMktTags = (props: { topicID: string }) => {
    const topicID = props.topicID;

    let selBtnStyle = styles.marketSpec__selectedBtn;
    let btnStyle = styles.marketSpec__unselectedBtn;

    if (!mktTags) {
      return <></>;
    } else {
      const handleChange = (val: string) => {
        // Make local variable posts to change and set posts while
        // using spread operator to make sure we get a new array created instead
        // of pointing to the same array in memory
        let newSelMktTags = [...selMktTags];
        if (newSelMktTags.indexOf(val) !== -1) {
          newSelMktTags.splice(selMktTags.indexOf(val), 1);
        } else {
          newSelMktTags.push(val);
        }

        setSelMktTags(newSelMktTags);
      };
      const buttons = mktTags.map((t) => {
        const buttonStyle = selMktTags.includes(t) ? selBtnStyle : btnStyle;
        const btnVariant = selMktTags.includes(t) ? "success" : "light";

        return (
          <Button
            variant={btnVariant}
            key={t}
            size="sm"
            className={`mr-1 ml-1 mt-1 ${styles.marketSpecDetails}`}
            onClick={() => {
              handleChange(t);
            }}
          >
            {t}
          </Button>
        );
      });

      return <span>{buttons}</span>;
    }
  };

  const ShowMarkets = () => {
    if (markets && !_.isEmpty(markets)) {
      return markets.map((m) => {
        if (selMktTags && selMktTags.length > 0) {
          if (m.marketTags) {
            const mTags = Object.values(m.marketTags);

            const isSelMkt = selMktTags.map((t) =>
              Object.values(mTags).includes(t)
            );

            if (isSelMkt && isSelMkt.length > 0 && isSelMkt.includes(true)) {
              return (
                <DisplayMarketCard
                  market={m}
                  topicLinkID={topicLinkID}
                  key={m.marketID}
                />
              );
            } else {
              return <></>;
            }
          } else {
            return <></>;
          }
        } else {
          return (
            <DisplayMarketCard
              market={m}
              topicLinkID={topicLinkID}
              key={m.marketID}
            />
          );
        }
      });
    } else {
      return <></>;
    }
  };

  if (markets && !_.isEmpty(markets)) {
    //let market = markets[0]
    // <Card className="rounded border-0 p-1 bg-white position-absolute top-0 start-50 translate-middle-x" style={{ width: '300px' }}>
    //style={{ font-size: '16', font-weight: '400' }}
    //<div className="card-container">{displayMarketCard()}</div>

    //
    //
    //ANCHOR: Market Card justify-content-center
    return (
      <Container>
        <Row className="w-100">
          <ShowAllMktTags topicID={topicID} />
        </Row>
        <Row className="justify-content-center">{ShowMarkets()}</Row>
      </Container>
    );
  } else {
    return <></>;
  }
};

const DisplayMarketCard = (props: {
  market: MarketData;
  topicLinkID: string;
}) => {
  const history = useHistory();
  const market = props.market;
  const topicLinkID = props.topicLinkID;
  const handleClick = () => {
    history.push(`/m/${topicLinkID}/${market.marketID}`, {
      market: market,
    });
  };

  const getMktTags = (market: MarketData) => {
    if (market.marketTags) {
      const tags = Object.values(market.marketTags);
      return tags.map((tag) => (
        <span className="label">
          <Badge pill variant="success">
            {tag}
          </Badge>
        </span>
      ));
    }
  };

  const getMktPrice = (market: MarketData) => {
    if (market.marketCurrency && market.marketDisplayPrice) {
      return market.marketCurrency + " " + market.marketDisplayPrice;
    }
  };

  return (
    //<div className="card-container bg-success">
    //2 columns for phones
    <Col xs={6} md={4} lg={3} className="d-flex flex-row m-0 p-2">
      <Card
        className="roundedCorners card-container bg-light mt-2 w-100"
        key={market.marketID}
        onClick={handleClick}
      >
        <Card.Img
          className="marketImg"
          as={MarketCartPlaceholder}
          variant="top"
          url={market.marketDisplayPhotoURL}
          // onError={(e) => {
          //   e.target.onerror = null;
          //   e.target.src =
          //     "https://firebasestorage.googleapis.com/v0/b/simply-link.appspot.com/o/Placeholder%2FmarketCart.png?alt=media&token=04790239-3f0e-4283-ae2b-d5d69260365c";
          // }}
        />
        <Card.Body>
          <Card.Title className="text-dark">{market.marketTitle}</Card.Title>

          <div style={{ fontSize: 24, fontWeight: "bolder" }}>
            {getMktPrice(market)}
          </div>
          <div>{getMktTags(market)}</div>
          <Card.Text className="text-muted marketDescText">
            {market.marketDesc}
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
    //</div>
  );
};

// const mapStateToProps = (state, ownProps) => {
//   return {
//     topic: state.topic.topic,
//   };
// };

// const mapDispatchToProps = (dispatch, ownProps) => ({
//   selectTopic(topic) {
//     dispatch(fetchTopicThunk(topic));
//   },
// });

// export default connect(mapStateToProps, mapDispatchToProps)(topicDetails);

// class topicDetails extends Component {
//   componentDidMount() {
//
//     const { topic } = this.props;
//     const id = this.props.match.params.id;
//     //const value = queryString.parse(this.props.location.search)
//     //const id = value.topics
//     topic.id = id;
//     this.props.selectTopic(topic);
//   }

//   componentDidUpdate() {
//     const { topic } = this.props;
//     this.refs.replaceTopicContent.innerHTML = topic.topicContent;
//     //this.refs.replaceTimestamp.innerHTML = moment((topic.topicTimestamp).slice(0,-2), "x").calendar()
//     this.refs.replaceTimestamp.innerHTML = topic.topicTimestamp; //.calendar();
//   }

//   render() {
//     const { topic } = this.props;
//     if (topic) {
//       return (
//         <div className="superContainer">
//           <Helmet>
//             <title> {topic.topicTitle} </title>
//             <meta property="og:title" content={topic.topicTitle} />
//             <meta property="og:image" content={topic.topicHeaderPath} />
//             <link
//               rel="icon"
//               type="image/png"
//               href="favicon.ico"
//               sizes="16x16"
//             />
//           </Helmet>
//           <div className="topicHeader">
//             <img
//               className="topicHeaderImg"
//               src={topic.topicHeaderPath}
//               alt=""
//             ></img>
//           </div>
//           <div id="mainContainer">
//             <div>
//               <div className="avatar">
//                 <div className="avatar-image">
//                   <img
//                     className="avatarImg"
//                     src={topic.userProfilePicPath}
//                     alt=""
//                   ></img>
//                 </div>
//                 <div className="avatar-name">{topic.topicCreatorName}</div>
//                 <div ref="replaceTimestamp" className="avatar-timestamp"></div>
//               </div>
//               <span>
//                 <h1 className="title-font">{topic.topicTitle}</h1>
//               </span>
//               <p ref="replaceTopicContent"></p>
//             </div>
//           </div>
//         </div>
//       );
//     } else {
//       return (
//         <div>
//           <p>Loading... </p>
//         </div>
//       );
//     }
//   }
// }

// const mapStateToProps = (state, ownProps) => {
//   return {
//     topic: state.topic.topic,
//   };
// };

// const mapDispatchToProps = (dispatch, ownProps) => ({
//   selectTopic(topic) {
//     dispatch(fetchTopicThunk(topic));
//   },
// });

// export default connect(mapStateToProps, mapDispatchToProps)(topicDetails);
