import { database } from "src/config/fbConfig";
/**
|--------------------------------------------------
| src/firebase/firebaseGroups
|--------------------------------------------------
*/

export const fetchGroupCredentials = async (groupID: string) => {
  try {
    const ref = database.ref(`/groups/${groupID}/groupCredentials`);
    const snap = await ref.once("value");
    if (snap.exists()) {
      return snap.val();
    } else {
      return null;
    }
  } catch (error) {
    return error;
  }
};

export const fetchDeletedGroup = async (groupID: string) => {
  try {
    const ref = database.ref(`/groupsDeleted/${groupID}/deleteTimestamp`);
    const snap = await ref.once("value");
    return snap.exists;
  } catch (error) {
    return error;
  }
};

export const fetchGroupTopicDataFromFirebase = async (
  groupID: string,
  earliestTS: number,
  batchCount: number
) => {
  const ref = database.ref(`groups/${groupID}/groupTopics/`);
  const query = ref
    .orderByChild("timestmap")
    .endAt(earliestTS)
    .limitToLast(batchCount);
  try {
    const snapshot = await query.once("value");

    if (snapshot.exists()) {
      const groupTopicData = snapshot.val();
      return groupTopicData;
    } else {
      return null;
    }
  } catch (err) {
    return err;
  }
};
