import React, { useEffect, useState } from "react";
import moment from "moment";
import { useLocation, useParams, useHistory, Link } from "react-router-dom";
import queryString from "query-string";
import { Container, Row, Col, Button, ListGroup, Card } from "react-bootstrap";
import styles from "src/marketStyle.module.scss";
import { useTranslation } from "react-i18next";
import LoadingScreen from "src/components/loading";
import { OrderStatus, PaymentStatus } from "./marketSingleOrder";
import {
  useAppDispatch,
  AppDispatch,
  AppState,
  AppGetState,
} from "src/redux/store";
import { selectUser } from "src/selectors/userSelector";
import { MarketOrder, UserData } from "src/types/typings";
import {
  MakeSelectWithTopicLinkID,
  MakeSelectMarketOrders,
} from "src/components/topics/topicDetails";
import { fireFetchAllTopicMarketOrders } from "src/firebase/firebaseMarkets";
import { useSelector } from "react-redux";
import ShowNavBar from "src/components/navBar";

const makeSelectTopicCreator = (userID: string) => {
  return useSelector((state: AppState) => selectUser(state, userID));
};

//SECTION: MAIN FUNCTION
const TopicMarketAllOrders = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const queryStr = location.search;
  const values = queryString.parse(queryStr);
  const topicLinkID = values.id1 as string;
  const topic = MakeSelectWithTopicLinkID({ topicLinkID });
  const topicID = topic?.topicID;
  const creatorID = topic?.topicCreatorID || "";
  let creator: UserData | undefined = makeSelectTopicCreator(creatorID);
  const appDispatch = useAppDispatch();
  const [loadingName, setLoadingName] = useState("");
  const allMktOrders = MakeSelectMarketOrders({ topicLinkID });

  useEffect(() => {
    if (!isLoading && topicID) {
      setIsLoading(true);
      appDispatch(fireFetchAllTopicMarketOrders(topicID, topicLinkID));
    } else {
      setIsLoading(false);
    }
  }, [setIsLoading]);

  const handleBack2Topic = () => {
    history.push(`/topics/${topicLinkID}`);
  };

  const EmptyOrder = () => {
    return (
      <>
        <Container className="p-4">
          <div
            className={`mt-4 d-flex justify-content-center ${styles.emptyCart__title}`}
          >
            {t("market.emptyCart")}
          </div>

          <div className={`mt-4 d-flex justify-content-center`}>
            <Button
              variant="primary"
              className={`${styles.emptyCart__btn}`}
              onClick={handleBack2Topic}
            >
              {t("market.continue")}
            </Button>
          </div>
        </Container>
      </>
    );
  };

  if (!allMktOrders) {
    return <LoadingScreen />;
  }

  if (!topic || allMktOrders.length < 1) {
    return <EmptyOrder />;
  }

  const LayoutTopicMarketOrders = () => {
    return allMktOrders.map((mktOrder) => {
      return ShowTopicHeader(mktOrder, topic);
    });
  };

  // if (!topic || !topicID || !creator) {
  //
  //   return <LoadingScreen />;
  // }

  return (
    <>
      <ShowNavBar topicLinkID={topicLinkID} activeKey="order" />
      <Container className="p-4">
        <Card className={`bg-light ${styles.cartContainer}`} key={"1"}>
          <ListGroup variant="flush">{LayoutTopicMarketOrders()}</ListGroup>
        </Card>
      </Container>
    </>
  );
};
//!SECTION

const ShowTopicHeader = (selMktOrder: MarketOrder, topic) => {
  const { t, i18n } = useTranslation();
  const cur = selMktOrder.orderCurrency;
  const productPrice = selMktOrder.orderProductPrice;
  const topicLinkID = topic.topicLinkID;

  const totString = t("market.orderTotal") + ": " + cur + " " + productPrice;

  return (
    <ListGroup.Item>
      <Row className="ml-1 mr-1">
        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
          <Row className={`${styles.marketTitle}`}>
            <Link
              to={{
                pathname: "/mr",
                search: `?id1=${topicLinkID}&id2=${selMktOrder.orderID}`,
              }}
            >
              {t("market.orderNum")}
              {selMktOrder.orderUID}
            </Link>
          </Row>

          <Row className={`${styles.mktOrder__orderTimestamp}`}>
            {moment(selMktOrder.orderTS).format("LLL")}
          </Row>

          <LayoutOrderMktItems selMktOrder={selMktOrder} />
        </Col>

        <Col
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          className={`d-flex flex-column align-items-md-end`}
        >
          <Row className="mt-2">
            <OrderStatus selMktOrder={selMktOrder} />
          </Row>
          <Row className="mt-2">
            <PaymentStatus selMktOrder={selMktOrder} />
          </Row>

          <Row className={`mt-2 ${styles.marketTitle}`}>{totString}</Row>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

const LayoutOrderMktItems = (props: { selMktOrder: MarketOrder }) => {
  const { t, i18n } = useTranslation();
  const selMktOrder = props.selMktOrder;
  const orderSpecs = selMktOrder.orderSpecs;

  let res = <></>;
  if (orderSpecs) {
    return (
      <>
        {orderSpecs.map((orderSpec) => {
          return (
            <Row className={`${styles.mktOrder__orderTitle}`}>
              {orderSpec.specMktTitle} - {orderSpec.specDesc} x{" "}
              {orderSpec.specQuantity}
            </Row>
          );
        })}
      </>
    );
  } else {
    return <></>;
  }
};

export default TopicMarketAllOrders;
