import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import LoadingScreen from "src/components/loading";
import { fetchTopicLinkFromTopicID } from "src/firebase/firebaseTopics";
import { useAppDispatch } from "src/redux/store";
import { TopicData, MarketData, MarketOrder } from "src/types/typings";
import { AppState } from "src/redux/store";
import { selectTopicWithTopicID } from "src/selectors/topicSelector";
import { useSelector } from "react-redux";
import _ from "lodash";

export const MakeSelectWithTopicID = ({ topicID }) => {
  const topic: TopicData | undefined = useSelector((state: AppState) =>
    selectTopicWithTopicID(state, topicID)
  );
  return topic;
};

const TopicDetailsQuery = () => {
  console.log("TopicDetailsQuery");
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const appDispatch = useAppDispatch();

  //To hide the navbar for some in app pages (terms & condition/privacy policy for now)
  const queryStr = location.search;
  const values = queryString.parse(queryStr);
  const tid = (values.tid as string) ?? null;

  const history = useHistory();

  const topic = MakeSelectWithTopicID({
    topicID: tid,
  });

  useEffect(() => {
    if (tid && topic && topic.topicLinkID) {
      history.push(`/topics/${topic.topicLinkID}`);
    }

    if (!topic || (topic && topic.topicNeedsDownload)) {
      setIsLoading(true);

      //got topicLinkID from topicID
      if (tid && !topic) {
        appDispatch(fetchTopicLinkFromTopicID(tid));
      }
    }
  }, [setIsLoading, topic, tid]);

  if (isLoading) {
    return <LoadingScreen />;
  } else {
    return <></>;
  }
};

export default TopicDetailsQuery;
