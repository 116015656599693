import React, { Component, Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import NotFound from "./components/notFound";
import TopicDetails from "./components/topics/topicDetails";
import TopicDetailsQuery from "./components/topics/topicDetailsQuery";
import TopicMarket from "./components/topics/topicMarket";
import MarketCheckout from "./components/topics/marketChkout/marketChkout";
import MarketReceipt from "./components/topics/marketReceipt";
import MarketCart from "./components/topics/marketCart";
import Home from "./components/home";
import { persistor } from "src/redux/store";
import store from "src/redux/store";
import { useAppDispatch } from "src/redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { loginStatusChanged } from "src/redux/reducers/authSlice";
import firebase from "firebase/app";
import { useEffect } from "react";
import { mapAuthUser } from "src/redux/reducers/authSlice";
import { Container, Spinner, Row } from "react-bootstrap";
import LoadingScreen from "./components/loading";
import { database } from "src/config/fbConfig";
//class App extends Component {

const App = () => {
  useEffect(() => {
    observeAuth();
  }, []);

  const observeAuth = () => {
    firebase.auth().onAuthStateChanged(async function (user) {
      if (user) {
        // User is signed in.
        console.log("!!! user is signed in!");
        // Update redux store
        const authUser = mapAuthUser(user);

        const userID = user.uid;
        const userRef = database.ref(`users/${userID}/userPlatform/web/lastTS`);
        await userRef.set(firebase.database.ServerValue.TIMESTAMP);

        store.dispatch(loginStatusChanged(authUser));
      } else {
        // No user is signed in.
        console.log("!!! user is NOT signed in!");
        store.dispatch(loginStatusChanged({}));
      }
    });
  };

  return (
    <Suspense
      fallback={
        <>
          <LoadingScreen />
        </>
      }
    >
      <>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <div className="App">
                <Switch>
                  {/*!Note: the id here is topicLink/id, it's not the real topicID*/}
                  <Route
                    strict
                    path="/topics/:id"
                    component={TopicDetails}
                  ></Route>
                  <Route path="/topics" component={TopicDetailsQuery}></Route>
                  <Route
                    path="/m/:topicLinkID/:marketID"
                    component={TopicMarket}
                  ></Route>
                  <Route path="/c/:topicLinkID" component={MarketCart}></Route>
                  <Route
                    path="/co/:topicLinkID"
                    component={MarketCheckout}
                  ></Route>
                  <Route path="/mr" component={MarketReceipt}></Route>
                  <Route path="/home" component={Home}></Route>
                  <Route exact path="/">
                    {<Redirect to="/home" />}
                  </Route>
                  <Route component={NotFound} />
                </Switch>
              </div>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </>
    </Suspense>
  );
};

export default App;

//path="/mr/:topicLinkID/:orderID"
