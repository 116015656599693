import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  Modal,
  Container,
  Row,
  Col,
  Button,
  Navbar,
  Nav,
  Jumbotron,
} from "react-bootstrap";
import * as yup from "yup";
import { AppState } from "src/redux/store";
import { useSelector } from "react-redux";
import _ from "lodash";
import SignInScreen from "src/firebase/firebaseui";
import { useTranslation } from "react-i18next";
import { langs } from "src/types/typings";
import { MakeSelectWithTopicLinkID } from "src/components/topics/topicDetails";
import ShowNavBar from "src/components/navBar";

//SECTION: MAIN FUNCTION
const Home = () => {
  const [isSigninModalShown, setIsSigninModalShown] = useState(false);

  const location = useLocation();

  const queryStr = location.search;
  const values = queryString.parse(queryStr);
  const topicLinkID = values.id1 as string;

  const topic = MakeSelectWithTopicLinkID({
    topicLinkID: topicLinkID,
  });

  const handleSigninModalClose = () => setIsSigninModalShown(false);

  const { t, i18n } = useTranslation();

  const isLoggedIn = useSelector((state: AppState) => {
    return state.auth.isLoggedIn;
  });

  useEffect(() => {
    //
  }, [isSigninModalShown]);

  const onClickSignin = () => {
    setIsSigninModalShown(true);
  };

  const ShowSigninModal = () => {
    return (
      <Modal
        show={isSigninModalShown}
        centered={true}
        animation={true}
        onHide={handleSigninModalClose}
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              fontSize: 24,
              fontWeight: "bolder",
            }}
          >
            Sign-in
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <SignInScreen onHide={handleSigninModalClose} />
        </Modal.Body>
      </Modal>
    );
  };

  const schema = yup.object().shape({
    signupEmail: yup.string().email("Invalid email").required("Required"),
  });

  const ShowHomeContent = () => {
    return (
      <Container className="mt-4">
        <Row xs={12} sm={12} md={12} lg={12} xl={12}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="homeTitle">
            {t("home.title")}
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="homeSubtitle">
            {t("home.subTitle")}
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="mt-4 homeSubsubtitle"
          >
            {t("home.subsubTitle")}
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mt-5">
            <Jumbotron>
              <Row className="d-flex justify-content-center">
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="homeSubtitle"
                >
                  {t("home.signupText")}
                </Col>

                <Col xs={12} sm={12} md={10} lg={7} xl={7} className="mt-4">
                  <Button
                    variant="primary"
                    className="homeSignupBtn w-100"
                    onClick={onClickSignin}
                  >
                    {t("home.signup")}
                  </Button>
                </Col>
              </Row>
            </Jumbotron>
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <>
      <ShowNavBar topicLinkID={topicLinkID} activeKey="home" />
      <ShowHomeContent />
      <ShowSigninModal />
    </>
  );
};

export default Home;
//!SECTION

{
  /*
    const handleLangChange = (val) => {
    setLangVal(val);
    changeLanguage(val);
  };
  <ToggleButtonGroup
name="lang"
type="radio"
value={langVal}
onChange={handleLangChange}
>
<ToggleButton value={langs.TC}>中</ToggleButton>
<ToggleButton value={langs.EN}>EN</ToggleButton>
</ToggleButtonGroup> */
}

//Alternative email signup form
{
  /* <Col xs={12} sm={12} md={10} lg={7} xl={7}>
                  <Formik
                    validationSchema={schema}
                    onSubmit={(values, { setSubmitting }) => {
                      
                      onClickSignup();
                    }}
                    initialValues={{
                      signupEmail: "",
                    }}
                  >
                    {({
                      handleChange,
                      handleSubmit,
                      touched,
                      errors,
                      values,
                    }) => (
                      <Form onSubmit={handleSubmit} className="mt-5 ">
                        <Form.Group controlId="email">
                          <Form.Control
                            type="text"
                            name="signupEmail"
                            placeholder="Enter email"
                            value={values.signupEmail}
                            onChange={handleChange}
                            isInvalid={!!errors.signupEmail}
                            isValid={touched.signupEmail && !errors.signupEmail}
                            className="homeEmail"
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.signupEmail}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Button
                          variant="primary"
                          className="homeSignupBtn w-100"
                          type="submit"
                        >
                          Sign up
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </Col> */
}
