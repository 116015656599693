import axios from "axios";
import { firebaseConfig } from "src/config/fbConfig";

export const getDynamicLink = async (
  topicLinkID: string,
  orderID: string,
  title: string,
  desc: string
) => {
  // const axiosInstance = axios.create({
  //   baseURL: "/api/",
  //   timeout: 2000,
  //   headers: { "X-Custom-Header": "foobar" },
  // });

  const apiKey = firebaseConfig.apiKey;

  const oriLink = `https://simplylink.com/mr?id1=${topicLinkID}&id2=${orderID}`;
  const info = {
    dynamicLinkInfo: {
      domainUriPrefix: "https://simplylink.page.link",
      link: oriLink,
      androidInfo: {
        androidPackageName: "com.simplylink.app",
        androidFallbackLink: oriLink,
      },
      iosInfo: {
        iosBundleId: "com.simplylink.app",
        iosFallbackLink: oriLink,
      },
      socialMetaTagInfo: {
        socialTitle: title,
        socialDescription: desc,
      },
    },
    suffix: {
      option: "UNGUESSABLE",
    },
  };

  //socialImageLink: string

  try {
    const dLink = await axios.post(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`,
      info
    );

    return dLink.data.shortLink;
  } catch (err) {
    console.log(err.response);
  }
};
