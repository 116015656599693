import { MarketOptions, TopicData, TopicState } from "src/types/typings";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

const topicsInitialState: TopicState = {
  data: {},
  isLoading: false,
  error: null,
};

export const topicSlice = createSlice({
  name: "topics",
  initialState: topicsInitialState,
  reducers: {
    fetchTopicSuccess(state, action: PayloadAction<Partial<TopicData>>) {
      const payload = action.payload;
      const topicID = payload.topicID as string;
      state.data[topicID] = Object.assign({}, state.data[topicID], payload);
    },
    fetchMarketOptionSuccess(
      state,
      action: PayloadAction<Partial<MarketOptions>>
    ) {
      const payload = action.payload;
      const tmpTopic: Partial<TopicData> = {};
      tmpTopic.topicMarketOptions = payload;
      const topicID = payload.topicID as string;

      state.data[topicID] = Object.assign({}, state.data[topicID], tmpTopic);
    },
  },
});

export const { fetchTopicSuccess, fetchMarketOptionSuccess } =
  topicSlice.actions;
export default topicSlice.reducer;

// const initState = {
//   topic: {
//     id: "",
//     topicContent: "Empty",
//     topicCreatorName: "",
//     topicCreatorID: "",
//     topicTitle: "",
//     topicHeaderPath: "",
//     topicTimestamp: 0,
//     userProfilePicPath: "",
//   },
// };

// const topicReducer = (state = initState, action) => {
//   switch (action.type) {
//     case "CREATE_TOPIC":
//
//       return state;
//     case "FETCH_TOPIC":
//
//       var newTopic = {
//         topic: {
//           id: action.payload.id,
//           topicContent: action.payload.topicContent,
//           topicCreatorName: action.payload.topicCreatorName,
//           topicCreatorID: action.payload.topicCreatorID,
//           topicTitle: action.payload.topicTitle,
//           topicHeaderPath: action.payload.topicHeaderPath,
//           topicTimestamp: action.payload.topicTimestamp,
//           userProfilePicPath: action.payload.userProfilePicPath,
//         },
//       };
//       return newTopic;
//     case "FETCH_TOPIC_ERROR":
//
//       return state;
//     default:
//       return state;
//   }
// };
// export default topicReducer;
