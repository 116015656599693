// Import FirebaseAuth and firebase.
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase";
import { Button, Container, Card } from "react-bootstrap";
import {
  AppDispatch,
  AppState,
  AppGetState,
  useAppDispatch,
} from "src/redux/store";

//Styles
import "./firebaseui-styling.global.css"; // Import globally.
import { database } from "src/config/fbConfig";
import { useTranslation } from "react-i18next";

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup", //popup/redirect
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  //signInSuccessUrl: '/signedIn',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    "apple.com",
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: function (authResult, redirectUrl) {
      return false;
    },
  },
};

function SignInScreen({ onHide }) {
  const { t, i18n } = useTranslation();
  const appDispatch = useAppDispatch();
  const isLoggedIn = useSelector((state: AppState) => {
    return state.auth.isLoggedIn;
  });

  const getLoginState =
    () => (dispatch: AppDispatch, getState: AppGetState) => {
      return getState().auth.isLoggedIn;
    };

  const [isSignedIn, setIsSignedIn] = useState(isLoggedIn); // Local signed-in state.
  const [show, setShow] = useState(false);
  // Listen to the Firebase Auth state and set the local state.
  // useEffect(() => {
  //   const unregisterAuthObserver = firebase
  //     .auth()
  //     .onAuthStateChanged(async (user) => {
  //       setIsSignedIn(!!user);
  //       if (user) {
  //         const userID = user.uid;
  //         const userRef = database.ref(
  //           `users/${userID}/userPlatform/web/lastTS`
  //         );
  //         await userRef.set(firebase.database.ServerValue.TIMESTAMP);
  //       }
  //     });
  //   return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  // }, []);

  useEffect(() => {
    const isLoggedIn = appDispatch(getLoginState());

    setIsSignedIn(isLoggedIn);
  }, [getLoginState]);

  if (!isSignedIn) {
    return (
      <div>
        <StyledFirebaseAuth
          className="firebaseUi"
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
        />
      </div>
    );
  }

  return (
    <div className="loginContainer">
      <p className="loginText">
        {t("signin.welcome")} {firebase.auth().currentUser?.displayName ?? ""}!
        {`\n`}
        {t("signin.welcomeContent")}
      </p>
      <Button variant="primary" onClick={onHide}>
        {t("general.continue")}
      </Button>
      <Button
        variant="light"
        onClick={() => firebase.auth().signOut()}
        className="mt-2"
      >
        {t("signin.signout")}
      </Button>
    </div>
  );
}

// function SignInScreen() {
//   return (
//     <>
//       <div>
//         <h1>My App</h1>
//         <p>Please sign-in:</p>
//         <StyledFirebaseAuth
//           uiConfig={uiConfig}
//           firebaseAuth={firebase.auth()}
//         />
//       </div>
//     </>
//   );
// }

export default SignInScreen;
