import React, { Suspense } from "react";
import { Spinner, Image } from "react-bootstrap";
import { useImage } from "react-image";
import marketCart from "src/images/marketCart.png";
import headerPlaceholder from "src/images/headerPlaceholder.png";
import userIcon from "src/images/userIcon.png";

function UserAvatarImg(props: {
  url: string;
  thumbnail: boolean;
  roundedCircle: boolean;
}) {
  const url = props.url;
  const thumbnail = props.thumbnail;
  const roundedCircle = props.roundedCircle;
  const { src } = useImage({
    srcList: [url, userIcon],
  });

  return (
    <Image
      src={src}
      className="avatarImg"
      thumbnail={thumbnail}
      roundedCircle={roundedCircle}
    />
  );
}

function MarketCartImg(props: { url: string; className: string }) {
  const url = props.url;
  const className = props.className;
  const { src } = useImage({
    srcList: [url, marketCart],
  });

  return <Image src={src} className={className} loading="lazy" />;
}

function MarketSpecImg(props: { url: string; className: string }) {
  const url = props.url;
  const className = props.className;
  const { src } = useImage({
    srcList: [url, headerPlaceholder],
  });

  return <Image src={src} className={className} loading="lazy" />;
}

function TopicHeaderImg(props: { url: string }) {
  const url = props.url;
  const { src } = useImage({
    srcList: [url, headerPlaceholder],
  });

  return <Image src={src} className="topicHeaderImg" />;
}

export function MarketSpecPlaceholder(props: {
  url: string;
  className: string;
}) {
  const url = props.url;
  const className = props.className;
  return (
    <Suspense fallback={<Image src={headerPlaceholder} />}>
      <MarketSpecImg url={url} className={className} />
    </Suspense>
  );
}

export function MarketCartPlaceholder(props: {
  url: string;
  className: string;
}) {
  const url = props.url;
  const className = props.className;
  return (
    <Suspense fallback={<Image src={marketCart} />}>
      <MarketCartImg url={url} className={className} />
    </Suspense>
  );
}

export function TopicHeader(props: { url: string }) {
  const url = props.url;
  return (
    <Suspense fallback={<Image src={headerPlaceholder} />}>
      <TopicHeaderImg url={url} />
    </Suspense>
  );
}

export function UserAvatarPlaceholder(props: {
  url: string;
  thumbnail: boolean;
  roundedCircle: boolean;
}) {
  const url = props.url;
  const thumbnail = props.thumbnail;
  const roundedCircle = props.roundedCircle;
  return (
    <Suspense fallback={<Image src={userIcon} />}>
      <UserAvatarImg
        url={url}
        thumbnail={thumbnail}
        roundedCircle={roundedCircle}
      />
    </Suspense>
  );
}
