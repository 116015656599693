/**
|--------------------------------------------------
| src/firebase/firebaseTopics
|--------------------------------------------------
*/
import { database } from "src/config/fbConfig";
import { AppDispatch, AppState, AppGetState } from "src/redux/store";
import {
  storeTopicDetailsToRedux,
  fetchTopicGroups,
  prepareTopicData,
} from "src/redux/utils/topicUtils";
import { fetchOnePerson } from "src/redux/reducers/userSlice";

import { fetchTopicSuccess } from "src/redux/reducers/topicSlice";
import { UserData, TopicData, GroupData } from "src/types/typings";
import cloneDeep from "lodash/cloneDeep";

//Get the topic details, except group names, avatars or user names for DM
export const fetchSingleTopicDetails = async (topicID: string) => {
  const ref = database.ref(`/topics/${topicID}`);
  const snap = (await ref.once("value")) as Record<string, any>;

  if (snap.exists()) {
    return snap;
  } else {
    //No snap

    return null;
  }
};

export const fetchTopicLinkFromTopicID =
  (topicID: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
    const topicLinkID = await fireFetchTopicLinkIDFromTopicID(topicID);
    if (topicLinkID !== null && topicLinkID !== "") {
      dispatch(fetchSingleTopicFromTopicLink(topicLinkID));
    } else {
      console.log("no topicLinkID");
    }
  };

export const fetchSingleTopicFromTopicLink =
  (topicLinkID: string) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    const topicID = await fetchTopicIDFromTopicLinkID(topicLinkID);
    if (topicID !== null && topicID !== "") {
      let topic: Partial<TopicData> = {
        topicID: topicID,
        topicLinkID: topicLinkID,
      };
      dispatch(fetchSingleTopic(topic));
    }
  };

export const checkTopicLastTS =
  (oldTopic: TopicData) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    const topicLastTS = await fetchTopicLastTS(oldTopic.topicID);
    if (topicLastTS !== null && oldTopic.topicLastTimestamp !== topicLastTS) {
      const topic = cloneDeep(oldTopic);
      topic.topicNeedsDownload = true;
      dispatch(fetchTopicSuccess(topic));
    }
  };

const fetchTopicIDFromTopicLinkID = async (topicLinkID: string) => {
  const ref = database.ref(`/topicLink/${topicLinkID}`);
  const snap = (await ref.once("value")) as Record<string, any>;
  if (snap.exists()) {
    return snap.val() as string;
  } else {
    return null;
  }
};

const fireFetchTopicLinkIDFromTopicID = async (topicID: string) => {
  const ref = database.ref(`/topicLink`);
  console.log("looking for topicID", topicID);
  const snap = (await ref
    .orderByValue()
    .equalTo(topicID)
    .once("value")) as Record<string, any>;
  if (snap.exists()) {
    const keys = Object.keys(snap.val()) as [string];
    if (keys.length > 0) {
      return keys[0];
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const fetchTopicLastTS = async (topicID: string) => {
  const ref = database.ref(`/topics/${topicID}/topicLastTimestamp`);
  const snap = (await ref.once("value")) as Record<string, any>;
  if (snap.exists()) {
    return snap.val() as number;
  } else {
    return null;
  }
};

export const fetchSingleTopic =
  (topic: Partial<TopicData>) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    // let topic: Partial<TopicData> = {
    //   topicID: topicID,
    // };

    const topicID = topic.topicID as string;
    if (topicID === "") {
      return;
    }
    const topicSnapshot = await fetchSingleTopicDetails(topicID);

    if (topicSnapshot) {
      dispatch(storeTopicDetailsToRedux(topicSnapshot));

      const topicDM = topicSnapshot.val().topicDM;
      let groups: Partial<GroupData>[] = [];
      let user: Partial<UserData> = {};
      if (!topicDM) {
        //Fetch Group Data
        const topicGroupsData = topicSnapshot.val().topicGroups;
        //Fetch topic groups
        const groupIDs = Object.keys(topicGroupsData).filter(
          (k) => topicGroupsData[k]
        );
        groups = await dispatch(fetchTopicGroups(groupIDs));
        let creatorID = topicSnapshot.val().topicCreatorID;
        if (creatorID === null || creatorID === "") {
          return;
        }
        user = await dispatch(fetchOnePerson(creatorID));

        const finalTopic = await prepareTopicData(
          groups,
          user,
          topicSnapshot,
          topic
        );
        dispatch(fetchTopicSuccess(finalTopic));
        return finalTopic;
      } else {
        //no topic snapshot
        return {};
      }
    }
  };
