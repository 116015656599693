import React from "react";
import { Col } from "react-bootstrap";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Login from "../../login/login";

import _ from "lodash";
import { useFormikContext } from "formik";
import styles from "src/marketStyle.module.scss";
import {
  MarketOptions,
  marketDeliveryOption,
  marketShippingFeeOption,
  FormFields,
} from "src/types/typings";
import { MakeSelectMarketTotal } from "src/components/topics/marketCart";
import { useTranslation } from "react-i18next";

//SECTION: SUMMARY
const OrderSummary = (props: { marketOptions: Partial<MarketOptions> }) => {
  const { values } = useFormikContext<FormFields>();
  const { t, i18n } = useTranslation();
  const marketOptions = props.marketOptions;
  const topicID = marketOptions.topicID;

  const tot = MakeSelectMarketTotal({
    topicID: topicID,
  });

  const totProductPrice = tot["totPrice"];
  const cur = tot["cur"];
  const productTot =
    `${t("market.chkoutPrdTotal")}` + ": " + cur + " " + totProductPrice;
  let marketShippingFeeText = "";
  const shipping = marketOptions.mktShipping;
  let shippingPrice = 0;
  let shippingStr = "";

  if (values.orderDeliveryMethod == marketDeliveryOption.COURIER) {
    switch (shipping) {
      case marketShippingFeeOption.NOTSET:
        marketShippingFeeText = `${t("market.chkoutContactSeller")}`;
        shippingStr = marketShippingFeeText;
        break;
      case marketShippingFeeOption.ONDELIVERY:
        marketShippingFeeText = `${t("market.chkoutOnDelivery")}`;
        shippingStr = marketShippingFeeText;
        shippingPrice = 0;
        break;
      case marketShippingFeeOption.FIXED:
        marketShippingFeeText = (marketOptions.mktShippingFee ?? 0).toString();
        shippingPrice = marketOptions.mktShippingFee ?? 0;
        shippingStr = cur + " " + marketOptions.mktShippingFee ?? 0;
        if (
          marketOptions.mktMinFreeShippingFee &&
          marketOptions.mktMinFreeShippingFee > 0 &&
          totProductPrice >= marketOptions.mktMinFreeShippingFee
        ) {
          marketShippingFeeText = `${t("market.chkoutFree")}`;
          shippingStr = marketShippingFeeText;
          shippingPrice = 0;
        }
        break;
      case marketShippingFeeOption.FREE:
        marketShippingFeeText = `${t("market.chkoutFree")}`;
        shippingStr = marketShippingFeeText;
        shippingPrice = 0;
        break;
      default:
        break;
    }
  }

  const finalPrice = totProductPrice + shippingPrice;

  values.orderCourierPaymentTxt = marketShippingFeeText;
  values.orderShippingFee = shippingPrice;
  values.orderFinalPrice = finalPrice;
  values.orderCur = cur;

  let finalTotal =
    `${t("market.chkoutPrdFinal")}` + ": " + cur + " " + finalPrice;
  let shippingStatement = <></>;
  if (shippingStr !== "") {
    shippingStatement = (
      <Col xs="12" className={`${styles.cartSpecQuanChkout}`}>
        {t("market.chkoutShipTotal")}: {shippingStr}
      </Col>
    );
  }

  return (
    <Col xs="12" className="mb-4">
      <Col xs="12" className={`${styles.cartSpecQuanChkout}`}>
        {productTot}
      </Col>
      {shippingStatement}
      <Col xs="12" className={`mt-2 ${styles.cartSpecTotPrice}`}>
        {finalTotal}
      </Col>
    </Col>
  );
};

export default OrderSummary;
