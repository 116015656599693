/**
|--------------------------------------------------
| src/redux/selectors/marketSelector
|--------------------------------------------------
*/

import { createSelector } from "reselect";
import { createCachedSelector } from "re-reselect";
import { AppState } from "src/redux/store";
import { selectTopic } from "./topicSelector";
import { MarketData, MarketSpec } from "src/types/typings";
import _ from "lodash";
import { pseudoRandomBytes } from "crypto";
import { string } from "yup/lib/locale";
import { resolveModuleNameFromCache } from "typescript";

export const selectTopicMarketOrders = createCachedSelector(
  selectTopic,
  (state: AppState) => state.marketOrders.data,
  (_, topicLinkID: string) => topicLinkID,
  (topic, marketOrders, topicLinkID) => {
    if (topic && !_.isEmpty(topic)) {
      const topicID = topic.topicID;
      const marketOrdersArr = Object.values(marketOrders);
      const filtered = marketOrdersArr.filter(
        (order) => order.topicID === topicID
      );

      if (filtered.length > 0) {
        const sorted = filtered.slice().sort((a, b) => b.orderTS - a.orderTS);
        return sorted;
      }
    }
  }
)(
  (state, topicLinkID) => topicLinkID //use topicID as cacheKey
);

export const selectTopicMarkets = createCachedSelector(
  selectTopic,
  (state: AppState) => state.markets.data,
  (_, topicLinkID: string) => topicLinkID,
  (topic, markets, topicLinkID) => {
    if (topic && !_.isEmpty(topic)) {
      const topicID = topic.topicID;
      const marketArr = Object.values(markets);

      const filtered = marketArr.filter((market) => market.topicID === topicID);

      //if (filtered.length > 0) {
      const sorted = filtered
        .slice()
        .sort((a, b) => a.marketOrder - b.marketOrder);
      return sorted;
      //}
    }
  }
)(
  (state, topicLinkID) => topicLinkID //use topicID as cacheKey
);

export const selectTopicMarketTags = createSelector(
  (state: AppState) => state.markets.data,
  (_, topicID: string) => topicID,
  (markets, topicID) => {
    const marketArr = Object.values(markets);
    const filteredMkts = marketArr.filter(
      (market) => market.topicID === topicID
    );
    let uniqueTags: string[] = [];
    filteredMkts.map((m) => {
      const tags = m.marketTags;
      if (tags) {
        const tagsArr = Object.values(tags);
        tagsArr.map((t) => {
          if (!uniqueTags.includes(t)) {
            uniqueTags.push(t);
          }
        });
      }
    });
    return uniqueTags;
  }
);

export const selectMarketOptions = createSelector(
  (state: AppState) => state.topics.data,
  (_, topicID: string) => topicID,
  (topics, topicID) => {
    const topic = topics[topicID];
    if (topic) {
      return topic.topicMarketOptions;
    }
  }
);

// export const selectPayment = createSelector(
//   (state: AppState) => state.marketOrders.data,
//   (_, orderID: string) => orderID,
//   (_, paymentID: string) => paymentID,
//   (orders, orderID, paymentID) => {
//     const order = orders[orderID];
//     const payment = order.orderPayment
//     return payment
//   }
// )

//Returns a record of <topicID, MarketSpec[]>
export const selectCartSpecs = createSelector(
  (state: AppState) => state.marketSpecs.data,
  (_, topicID: string) => topicID,
  (marketSpecsData, topicID) => {
    const marketSpecs = Object.values(marketSpecsData);
    const res: Record<string, MarketSpec[]> = {};

    marketSpecs
      .filter((s) => {
        //return s;
        return s.orderQuantity > 0;
      })
      .map((v) => {
        const specTopicID = v.specTopicID;
        if (specTopicID === topicID) {
          (res[specTopicID] || (res[specTopicID] = [])).push(v);
        }
      });

    return res;

    //return orderSpecs;
    // return m.marketSpecsCln.filter((spec) => {
    //   if (spec && spec.orderQuantity && spec.orderQuantity > 0) {
    //     return spec
    //   } else {
    //     return undefined
    //   }
    // }).filter(s=>s!==undefined)

    //const orderSpecsCln = orderSpecs.filter(s=>s!==undefined)
  }
);

export const selectTopicOrderSpecs = createSelector(
  (state: AppState) => state.marketSpecs.data,
  (_, topicID: string) => topicID,
  (marketSpecsData, topicID) => {
    const marketSpecs = Object.values(marketSpecsData);

    const res = marketSpecs.filter((s) => {
      //return s;
      return s.orderQuantity > 0 && s.specTopicID === topicID;
    });

    return res;
  }
);

export const selectMarket = createSelector(
  (state: AppState) => state.markets.data,
  (_, marketID: string) => marketID,
  (markets, marketID) => {
    const selMarket = markets[marketID] as MarketData;

    return selMarket;
  }
);

export const selectAllMarketSpecs = createSelector(
  (state: AppState) => state.marketSpecs.data,
  (_, marketID: string) => marketID,
  (marketSpecsData, marketID) => {
    const marketSpecs = Object.values(marketSpecsData);
    const selSpecs = marketSpecs.filter((s) => {
      return s.specMarketID === marketID;
    });
    return selSpecs;
  }
);

const selectTopicMarketsFromTopicID = createSelector(
  (state: AppState) => state.markets.data,
  (_, topicID: string) => topicID,
  (marketData, topicID) => {
    const markets = Object.values(marketData);
    return markets.filter((market) => {
      if (market.topicID === topicID) {
        return market;
      }
    });
  }
);

export const selectMktTotal = createCachedSelector(
  selectTopicMarketsFromTopicID,
  (state: AppState) => state.marketSpecs.data,
  (_, topicID: string) => topicID,
  (topicMarkets, marketSpecs, topicID) => {
    let totalPrice = 0;
    let currency = "";

    if (topicMarkets && topicMarkets.length > 0) {
      const topicMarket = topicMarkets[0];
      currency = topicMarket.marketCurrency;
    }

    if (marketSpecs) {
      const specsData = Object.values(marketSpecs);
      specsData.map((spec) => {
        if (spec.specTopicID === topicID) {
          if (spec.specPrice && spec.orderQuantity) {
            totalPrice = totalPrice + spec.specPrice * spec.orderQuantity;
          }
        }
      });
    }

    return { cur: currency, totPrice: totalPrice };
  }
)(
  (state, topicID) => topicID //use topicID as cacheKey
);

export const selectMarketOrder = createSelector(
  (state: AppState) => state.marketOrders.data,
  (_, orderID: string) => orderID,
  (marketOrders, orderID) => {
    if (marketOrders[orderID]) {
      return marketOrders[orderID];
    }
  }
);

export const selectMarketSepc = createSelector(
  (state: AppState) => state.marketSpecs.data,
  (_, market: MarketData, specT2IDs: string[]) => market,
  (_, market: MarketData, specT2IDs: string[]) => specT2IDs,
  (allMarketSpecs, market, specT2IDs) => {
    const allMarketSpecsArr = Object.values(allMarketSpecs);

    if (market && allMarketSpecsArr) {
      const filtered = allMarketSpecsArr.filter((spec) => {
        if (spec.specMarketID === market.marketID) {
          if (!spec.specT2IDs) {
            //only 1 spec, no specT2IDs

            return spec;
          } else if (spec.specT2IDs && spec.specT2IDs.length > 0) {
            const specT2Arr = spec.specT2IDs;

            if (specT2Arr && specT2Arr.length == specT2IDs.length) {
              //https://stackoverflow.com/questions/7837456/how-to-compare-arrays-in-javascript
              const array2Sorted = specT2Arr.slice().sort();
              if (
                specT2IDs.length === specT2Arr.length &&
                specT2IDs
                  .slice()
                  .sort()
                  .every(function (value, index) {
                    return value === array2Sorted[index];
                  })
              ) {
                return spec;
              }
            }
          }
        }
      });
      if (filtered && filtered.length === 1) {
        return filtered[0];
      }
    }
  }
);

// if (market && market.marketSpecsCln) {
//   const specs = market.marketSpecsCln;
//
//   //specT2IDs.length > 0 to ensure there's spec selected
//   if (specs) {
//     const filtered = market.marketSpecsCln.filter((spec) => {
//       if (!spec.specT2IDs) {
//         //only 1 spec, no specT2IDs
//         return spec;
//       } else if (spec.specT2IDs && spec.specT2IDs.length > 0) {
//         const specT2Arr = spec.specT2IDs;

//         if (specT2Arr && specT2Arr.length == specT2IDs.length) {
//
//             "we got specT2IDs",
//             specT2Arr,
//             "and T2IDs are",
//             specT2IDs
//           );

//           //https://stackoverflow.com/questions/7837456/how-to-compare-arrays-in-javascript
//           const array2Sorted = specT2Arr.slice().sort();
//           if (
//             specT2IDs.length === specT2Arr.length &&
//             specT2IDs
//               .slice()
//               .sort()
//               .every(function (value, index) {
//                 return value === array2Sorted[index];
//               })
//           ) {
//             return spec;
//           }

//           //This does not work on initial load
//           // const filteredSpec = _.isEqual(
//           //   specT2Arr.sort(),
//           //   specT2IDs.sort()
//           // );
//           //
//           // //return _.isEqual(spec.specT2IDs.sort(), specT2IDs.sort());
//           // return filteredSpec;
//         } else {
//           return undefined;
//         }
//       }
//     });
//     if (filtered && filtered.length === 1) {
//       return filtered[0];
//     }
//   }
// }
