/**
|--------------------------------------------------
| src/firebase/firebaseUsers
|--------------------------------------------------
*/

import { database } from "src/config/fbConfig";
import { UserData, TopicData, GroupData } from "src/types/typings";

export const fetchUserCredentials = (userID: string) => {
  const defer: Promise<Partial<UserData>> = new Promise((resolve, reject) => {
    database
      .ref(`/users/${userID}/credentials`)
      .once("value")
      .then(
        (snap) => {
          const userData = snap.val();
          resolve(userData);
        },
        (err) => {
          reject(err);
        }
      )
      .catch((error) => {
        reject(error);
      });
  });
  return defer;
};
