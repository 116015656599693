import React, { useEffect, useState, useRef } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";
import {
  Modal,
  Alert,
  Container,
  Row,
  Col,
  Badge,
  Button,
  InputGroup,
  FormControl,
  Image,
  Accordion,
  Card,
} from "react-bootstrap";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MarketCartPlaceholder, MarketSpecPlaceholder } from "./holderImg";

import {
  TopicData,
  MarketData,
  MarketSpecT1,
  MarketSpecT2,
  MarketSpec,
  MarketOptions,
  marketShippingFeeOption,
} from "src/types/typings";
import _ from "lodash";
import styles from "src/marketStyle.module.scss";
import { IoRemove, IoAdd } from "react-icons/io5";
import { FiTruck, FiShoppingCart } from "react-icons/fi";
import { BsCardChecklist } from "react-icons/bs";
import { MdPayment } from "react-icons/md";
import { useSelector } from "react-redux";
import { AppState, useAppDispatch } from "src/redux/store";
import {
  selectMarket,
  selectMarketSepc,
  selectMarketOptions,
  selectAllMarketSpecs,
} from "src/selectors/marketSelector";
import { fetchMarketSpecSuccess } from "src/redux/reducers/marketSpecSlice";
import { langs } from "src/types/typings";
import ShowNavBar from "src/components/navBar";
import { useTranslation } from "react-i18next";
import { fetchSingleTopicMarketThunk } from "src/redux/reducers/marketSlice";
import { fetchSingleTopicFromTopicLink } from "src/firebase/firebaseTopics";
import {
  fireFetchTopicMarketOptions,
  checkMarketLastTS,
} from "src/firebase/firebaseMarkets";
import { MakeSelectWithTopicLinkID } from "src/components/topics/topicDetails";
import LoadingScreen from "src/components/loading";
import { SRLWrapper } from "simple-react-lightbox";
import { constants } from "http2";

const MakeSelectMarket = (marketID: string) => {
  const selMkt: MarketData | undefined = useSelector((state: AppState) => {
    return selectMarket(state, marketID);
  });
  return selMkt;
};

export const MakeSelectAllMarketSpecs = (marketID: string) => {
  const allMktSpecs = useSelector((state: AppState) => {
    return selectAllMarketSpecs(state, marketID);
  });
  return allMktSpecs;
};

const MakeSelectMarketSpec = ({ specT2Records, market }) => {
  const selSpec: Partial<MarketSpec> | undefined = useSelector(
    (state: AppState) => {
      const specT2IDs = Object.values(specT2Records) as string[];

      return selectMarketSepc(state, market, specT2IDs);
    }
  );
  return selSpec;
};

const MakeSelectMarketOptions = ({ topicID }) => {
  const selMarketOptions: Partial<MarketOptions> | undefined = useSelector(
    (state: AppState) => {
      const option = selectMarketOptions(state, topicID);

      return option;
    }
  );
  return selMarketOptions;
};

const GetMktPhotos = (market: MarketData | undefined) => {
  if (!market || !market.marketPhotos) {
    return;
  }
  const photoIDs = Object.keys(market.marketPhotos);
  const photos = Object.values(market.marketPhotos);

  return photoIDs.map((photoID) => {
    const photo = market.marketPhotos[photoID];
    return (
      <div className="slick-slide" key={photoID}>
        <MarketCartPlaceholder
          className={`"mx-auto rounded slick-slide-image"`}
          url={photo.photoUrl}
        />
      </div>
    );
  });
};

//SECTION: MAIN FUNCTION
const TopicMarket = () => {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const curLang = i18n.language;
  let initLang = langs.TC;
  if (curLang == "en") {
    initLang = langs.EN;
  } else if (curLang == "tc") {
    initLang = langs.TC;
  }
  const [langVal, setLangVal] = useState<langs>(initLang);
  const changeLanguage = (val: langs) => {
    let lng = "en";
    switch (val) {
      case langs.EN:
        lng = "en";
        break;
      case langs.TC:
        lng = "tc";
        break;
    }
    i18n.changeLanguage(lng);
  };

  const location = useLocation();

  //const market = location.state.market as MarketData;

  const { topicLinkID, marketID } = useParams();
  const appDispatch = useAppDispatch();

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  const [selSpecsT2, setSelSpecsT2] = useState<Record<string, string>>({});
  const [orderQuan, setOrderQuan] = useState(1);
  const [counterAlertShow, setCounterAlertShow] = useState(false);
  const [specAlertShow, setSpecAlertShow] = useState(false);
  const [isOOSAlertShown, setIsOOSAlertShown] = useState(false);
  const [selSpec, setSelSpec] = useState<Partial<MarketSpec>>();
  const [mktOrderQuan, setMktOrderQuan] = useState(0);
  const [specAvailQuan, setSpecAvailQuan] = useState(-1);
  const [isAddCart, setIsAddCart] = useState(false);
  const scrollRef = useRef<HTMLInputElement>(null);

  //Selectors
  const topic = MakeSelectWithTopicLinkID({
    topicLinkID: topicLinkID,
  });
  const market = MakeSelectMarket(marketID) as MarketData | undefined;
  const allMktSpecs = MakeSelectAllMarketSpecs(marketID);

  const marketOptions = MakeSelectMarketOptions({
    topicID: topic?.topicID ?? "",
  });
  const getSelSpec = MakeSelectMarketSpec({
    specT2Records: selSpecsT2,
    market: market,
  });

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);

    //Total order quantities for this market across all specs
    let totquan = 0;
    allMktSpecs.map((spec) => {
      totquan += spec.orderQuantity ?? 0;
    });
    setMktOrderQuan(totquan);

    if (getSelSpec) {
      setSpecAlertShow(false);
      setSelSpec(getSelSpec);
      if (getSelSpec.specStock !== undefined && getSelSpec.specStock === 0) {
        setIsOOSAlertShown(true);
      } else if (
        getSelSpec.specStock !== undefined &&
        getSelSpec.specStock > 0
      ) {
        let availStock = getSelSpec.specStock;
        if (market && market.marketOrderLimit >= 0) {
          const availOrderLimit = market.marketOrderLimit - totquan;
          availStock = Math.min(availStock, availOrderLimit);
        }
        setSpecAvailQuan(availStock);
      }
    }

    if (specAlertShow && scrollRef.current) {
      scrollRef.current.scrollIntoView();
    }
  }, [
    slider1,
    slider2,
    selSpecsT2,
    counterAlertShow,
    specAlertShow,
    getSelSpec,
    setSelSpec,
    setSpecAlertShow,
    setMktOrderQuan,
  ]);

  //Make sure the market is up to date
  useEffect(() => {
    if (market) {
      appDispatch(checkMarketLastTS(market));
    }
  }, []);

  useEffect(() => {
    if (!topic) {
      setIsLoading(true);
      appDispatch(fetchSingleTopicFromTopicLink(topicLinkID));
    } else if (!market) {
      setIsLoading(true);
      appDispatch(
        fetchSingleTopicMarketThunk({
          topicID: topic.topicID,
          marketID: marketID,
        })
      );
    } else if (!marketOptions) {
      setIsLoading(true);
      appDispatch(fireFetchTopicMarketOptions(topic.topicID));
    } else {
      setIsLoading(false);
    }
  }, [setIsLoading, market, topic, marketOptions]);

  const getMktTags = (market: MarketData) => {
    if (market.marketTags) {
      const tags = Object.values(market.marketTags);
      return tags.map((tag) => (
        <span className="label" key={tag}>
          <Badge pill variant="success">
            {tag}
          </Badge>
        </span>
      ));
    }
  };

  const ShowMktSpecs = () => {
    if (!market) {
      return;
    }
    const specT1s = market.marketSpecT1sCln;
    return specT1s.map((specT1) => SetMarketSpecs(specT1));
  };

  const showSelSpecPhoto = () => {
    if (selSpec && market) {
      const photoURL =
        selSpec.specPhoto?.photoContentURL ?? market?.marketDisplayPhotoURL;
      //
      return (
        <div className="mt-4" key={photoURL}>
          <SRLWrapper options={lightboxOptions} key={photoURL}>
            <MarketSpecPlaceholder
              className={`"mx-auto rounded" ${styles.selSpecPhoto}`}
              url={photoURL}
            />
          </SRLWrapper>
        </div>
      );
    }
  };

  const showSelSpecPrice = () => {
    if (market && selSpec && selSpec.specPrice) {
      const price = selSpec.specPrice;
      return (
        <div className={styles.specPrice}>
          {market.marketCurrency} {selSpec.specPrice}
        </div>
      );
    }
  };
  //{t("market.receiptOrderStatus")}
  const handleAddCartClose = () => setIsAddCart(false);

  const history = useHistory();
  const handleBack2Topic = () => {
    setIsAddCart(false); //DO not go back for now
    //history.goBack();
    //history.push(`/topics/${topicLinkID}`);
  };

  const handleGo2Cart = () => {
    history.push(`/c/${topicLinkID}`);
  };

  const showAddCartModal = () => {
    if (isAddCart) {
      let contentTxt = `1 ${t("market.alertSuccessContent")}`;
      if (orderQuan > 1) {
        contentTxt = `${orderQuan} ${t("market.alertSuccessContent2")}`;
      }
      return (
        <Modal
          show={isAddCart}
          centered={true}
          animation={true}
          onHide={handleAddCartClose}
          size="sm"
        >
          <Modal.Header closeButton>
            <Modal.Title
              style={{
                ...styles,
                fontSize: 24,
                fontWeight: "bolder",
              }}
            >
              {t("market.alertSuccess")}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>{contentTxt}</p>
          </Modal.Body>

          <Modal.Footer>
            <Button block={true} variant="primary" onClick={handleBack2Topic}>
              {t("market.alertSuccessBack")}
            </Button>
            <Button
              block={true}
              variant="outline-secondary"
              onClick={handleGo2Cart}
            >
              {t("market.alertSuccessViewCart")}
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  };

  //Used to set a ceiling in setSelSpecStockLimit
  // const setSelSpecStockLimit = (selSpec: Partial<MarketSpec> | undefined) => {
  //   if (selSpec && selSpec.specStock && selSpec.specStock > 0) {
  //     setStockLimit(selSpec.specStock);
  //   }
  // };

  const onClickQuanUp = () => {
    if (!market) {
      return;
    }
    if (orderQuan) {
      if (selSpec) {
        setSpecAlertShow(false);
        const newQuan = orderQuan + 1;

        if (
          selSpec &&
          selSpec.specStock !== null &&
          selSpec.specStock !== undefined &&
          selSpec.specStock >= 0 &&
          newQuan > selSpec.specStock
        ) {
          setCounterAlertShow(true);
          return;
        }

        //Calculate all cart order quantities for all market specs for a market item
        // let totquan = 0;
        // allMktSpecs.map((spec) => {
        //   totquan += spec.orderQuantity ?? 0;
        // });
        const allMktSpecsCartQuan = mktOrderQuan + newQuan;

        if (
          market.marketOrderLimit == 0 ||
          (market.marketOrderLimit &&
            market.marketOrderLimit > 0 &&
            allMktSpecsCartQuan > market.marketOrderLimit)
        ) {
          setCounterAlertShow(true);
          return;
        } else {
          setOrderQuan(newQuan);
        }
      } else {
        setSpecAlertShow(true);
      }
    }
  };

  const onClickQuanDown = () => {
    if (orderQuan) {
      if (selSpec) {
        setSpecAlertShow(false);
        const newQuan = orderQuan - 1;

        if (newQuan >= 1) {
          setCounterAlertShow(false);
          setOrderQuan(newQuan);
        }
      } else {
        setSpecAlertShow(true);
      }
    }
  };

  const onClickAdd2Cart = () => {
    //Sanity Check
    if (!selSpec || selSpec.specStock === undefined) {
      setSpecAlertShow(true);
      return;
    }

    if (selSpec && selSpec.specStock === 0) {
      setIsOOSAlertShown(true);
      return;
    }

    //Calculate all cart order quantities for all market specs for a market item
    let totquan = 0;
    allMktSpecs.map((spec) => {
      totquan += spec.orderQuantity ?? 0;
    });
    console.log(
      "🚀 ~ file: topicMarket.tsx ~ line 182 ~ allMktSpecs.map ~ totquan",
      totquan
    );
    const allMktSpecsCartQuan = totquan + orderQuan;

    //Also count the quan of selected spec already in cart
    let updatedQuan = orderQuan;
    if (selSpec.orderQuantity && selSpec.orderQuantity > 0) {
      updatedQuan = updatedQuan + selSpec.orderQuantity;
    }

    if (
      updatedQuan &&
      updatedQuan > 0 &&
      selSpec.specStock >= 0 &&
      updatedQuan > selSpec.specStock
    ) {
      setCounterAlertShow(true);
      return;
    } else if (!updatedQuan || updatedQuan <= 0) {
      setCounterAlertShow(true);
      return;
    }

    if (
      market &&
      (market.marketOrderLimit == 0 ||
        (market.marketOrderLimit &&
          market.marketOrderLimit > 0 &&
          allMktSpecsCartQuan > market.marketOrderLimit))
    ) {
      setCounterAlertShow(true);
      return;
    }

    //Update Spec quan
    const newSpec: Partial<MarketSpec> = {};
    newSpec.specID = selSpec.specID;
    newSpec.orderQuantity = updatedQuan;
    appDispatch(fetchMarketSpecSuccess(newSpec));

    //Update Cart
    setIsAddCart(true);
  };

  //Delivery Details
  const ShowDelivery = () => {
    if (!market) {
      return;
    }
    //const topicID = market.topicID;
    //const marketOptions = MakeSelectMarketOptions({ topicID: topicID });
    let buffer = [] as JSX.Element[];
    if (!marketOptions) {
      return <></>;
    }

    if (marketOptions.mktShipping !== marketShippingFeeOption.NOTSET) {
      //Shipping fee not set, meaning no courier

      let shippingFeeEle: JSX.Element = <div key="s"></div>;
      let efLockerTxtEle: JSX.Element = <div key="ef"></div>;
      const courierTitleEle: JSX.Element = (
        <Row className={styles.marketDeliveryDetailsTitle} key="ct">
          {t("market.courier")}
        </Row>
      );

      switch (marketOptions.mktShipping) {
        //TODO: finish switch cases
        case marketShippingFeeOption.FIXED: {
          if (marketOptions.mktShippingFee) {
            const shippingFeeTxt =
              `${t("market.cartShippingFee")}: ` +
              (market.marketCurrency ?? "") +
              " " +
              marketOptions.mktShippingFee.toString();
            shippingFeeEle = (
              <li
                className={styles.marketDeliveryDetailsContent}
                key={"shippingFee"}
              >
                {shippingFeeTxt}
              </li>
            );
          }
        }
      }

      if (marketOptions.mktIsEFLocker) {
        const efLockerTxt = `${t("market.cartLockerAvail")}`;
        efLockerTxtEle = (
          <li className={styles.marketDeliveryDetailsContent} key={"EFLocker"}>
            {efLockerTxt}
          </li>
        );
      }

      buffer.push(courierTitleEle, shippingFeeEle, efLockerTxtEle);
    }

    if (
      marketOptions.mktPickupLocs &&
      Object.keys(marketOptions.mktPickupLocs).length > 0
    ) {
      const pickupTitleEle: JSX.Element = (
        <Row className={styles.marketDeliveryDetailsTitle} key="pu">
          {t("market.pickup")}
        </Row>
      );
      buffer.push(pickupTitleEle);

      const pickupLocs = Object.values(marketOptions.mktPickupLocs);
      pickupLocs.map((pickupLoc) => {
        const pickupTxtEle = (
          <li className={styles.marketDeliveryDetailsContent} key={pickupLoc}>
            {pickupLoc}
          </li>
        );
        buffer.push(pickupTxtEle);
      });
    }

    return buffer;
  };

  //Payment
  const ShowPayment = (marketOptions: Partial<MarketOptions> | undefined) => {
    //const topicID = market.topicID;
    //const marketOptions = MakeSelectMarketOptions({ topicID: topicID });
    let paymentBuffer = [] as JSX.Element[];
    if (!marketOptions) {
      return <></>;
    }
    if (marketOptions.mktPaymentDetails) {
      paymentBuffer.push(
        <Row className={styles.marketDeliveryDetailsTitle}>
          {t("market.cartPaymentOpts")}{" "}
        </Row>
      );
      const paymentDetailsEle = (
        <li className={styles.marketDeliveryDetailsContent}>
          {marketOptions.mktPaymentDetails}
        </li>
      );
      paymentBuffer.push(paymentDetailsEle);
    }

    if (marketOptions.mktPayments) {
      const mktPayments = Object.values(marketOptions.mktPayments);
      if (mktPayments.length > 0) {
        mktPayments.map((mktPayment) => {
          if (mktPayment && mktPayment.paymentTitle) {
            const paymentTxtEle = (
              <li
                className={styles.marketDeliveryDetailsContent}
                key={mktPayment.paymentID}
              >
                {mktPayment.paymentTitle}
              </li>
            );
            paymentBuffer.push(paymentTxtEle);
          }
        });
      }
    }
    return paymentBuffer;
  };

  const ShowSpecAlert = () => {
    if (specAlertShow) {
      return (
        <Alert
          variant="danger"
          onClose={() => setSpecAlertShow(false)}
          dismissible
        >
          <Alert.Heading>{t("market.alert")}</Alert.Heading>
          <p>{t("market.alertSpecs")}</p>
        </Alert>
      );
    } else {
      return <></>;
    }
  };

  const ShowOOSAlert = () => {
    if (isOOSAlertShown) {
      return (
        <Alert variant="danger">
          <Alert.Heading>{t("market.alert")}</Alert.Heading>
          <p>{t("market.alertOOS")}</p>
        </Alert>
      );
    } else {
      return <></>;
    }
  };

  const ShowCounterAlert = () => {
    if (counterAlertShow) {
      return (
        <Alert
          variant="danger"
          onClose={() => setCounterAlertShow(false)}
          dismissible
        >
          <Alert.Heading>{t("market.alert")}</Alert.Heading>
          <p>{t("market.alertMaxQuan")}</p>
        </Alert>
      );
    } else {
      return <></>;
    }
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "black" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "gray" }}
        onClick={onClick}
      />
    );
  }
  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    asNavFor: ".slider-nav",
  };

  const settingsThumbs = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    lazyLoad: "progressive",
    dots: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    infinite: true,
    centerPadding: "10px",
    arrows: false,
  };

  const SetMarketSpecs = (specT1: MarketSpecT1) => {
    const SetMarketSelSpecs = (specT2: MarketSpecT2) => {
      //const selSpecs: MarketSpecT2[] = selSpecsT2;
      //selSpecs.push(specT2);
      const specT1ID = specT1.specT1ID;
      const specT2ID = specT2.specT2ID;
      const specObj: Record<string, string> = {};
      specObj[specT1ID] = specT2ID;

      if (specT1ID && specT2ID) {
        setSelSpecsT2((state) => Object.assign({}, state, specObj));
      }
    };

    const [value, setValue] = useState({} as MarketSpecT2);
    const handleChange = (val: MarketSpecT2) => {
      //setValue(val);

      //Update selector selectMarketSepc
      SetMarketSelSpecs(val);
      //Update UI
      setValue(val);
      // setValue(state => ({
      //   ...state,
      //   property: value
      // }))
    };

    // useEffect(() => {
    //   setNav1(slider1);
    //   setNav2(slider2);
    // }, [slider1, slider2]);

    const getSpecT2s = (specT1: MarketSpecT1) => {
      let selBtnStyle = styles.marketSpec__selectedBtn;

      let btnStyle = styles.marketSpec__unselectedBtn;

      const specT2s = specT1.specT2s;

      const buttons = specT2s.map((specT2) => {
        const buttonStyle = specT2 === value ? selBtnStyle : btnStyle;
        const btnVariant = specT2 === value ? "dark" : "light";
        //const classes = `ml-1 ${buttonStyle}`

        return (
          <Button
            variant={btnVariant}
            size="sm"
            key={specT2.specT2ID}
            className={`mr-1 ml-1 ${styles.marketSpecDetails}`}
            onClick={() => {
              handleChange(specT2);
            }}
          >
            {specT2.specDetail}
          </Button>
        );
      });

      return <span key={specT1.specT1ID}>{buttons}</span>;
    };

    return (
      <Row key={specT1.specT1ID}>
        <span className={`${styles.marketSpecTitle}`}>{specT1.specTitle}</span>
        {getSpecT2s(specT1)}
      </Row>
    );
  };

  const slidePhotos = GetMktPhotos(market);

  const LayoutMktSpecs = () => {
    if (!market) {
      return;
    }
    if (market.marketSpecT1sCln && !_.isEmpty(market.marketSpecT1sCln)) {
      return (
        <Accordion defaultActiveKey="0" className="w-100">
          <Card className={`border-0 ${styles.shipCard}`}>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              <BsCardChecklist className={`${styles.marketDelivery__truck}`} />
              <span className={styles.marketDelivery}>{t("market.spec")}</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                {ShowMktSpecs()}

                {/* Spec Photo */}
                {showSelSpecPhoto()}

                {/* Spec Price */}
                {showSelSpecPrice()}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      );
    }
  };

  const LayoutDelivery = () => {
    if (
      marketOptions &&
      (marketOptions.mktShipping !== marketShippingFeeOption.NOTSET ||
        (marketOptions.mktPickupLocs &&
          Object.keys(marketOptions.mktPickupLocs).length > 0))
    ) {
      return (
        <Accordion defaultActiveKey="1" className="w-100">
          <Card className={`border-0 ${styles.shipCard}`}>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              <FiTruck className={`${styles.marketDelivery__truck}`} />
              <span className={styles.marketDelivery}>
                {t("market.delivery")}
              </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>{ShowDelivery()}</Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      );
    }
  };

  const LayoutPayment = () => {
    if (
      marketOptions &&
      marketOptions.mktPayments &&
      marketOptions.mktPayments.length > 0
    ) {
      return (
        <Accordion defaultActiveKey="2" className="w-100">
          <Card className={`border-0 ${styles.shipCard}`}>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              <MdPayment className={`${styles.marketDelivery__truck}`} />
              <span className={styles.marketDelivery}>
                {t("market.payment")}
              </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>{ShowPayment(marketOptions)}</Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      );
    }
  };

  const lightboxOptions = {
    settings: {
      lightboxTransitionSpeed: 0.1,
    },
    buttons: {
      showThumbnailsButton: false, //Note we cannot show the thumbnail as there'll be duplicate photos
      showAutoplayButton: false,
      showDownloadButton: false,
      showNextButton: true,
      showPrevButton: true,
    },
    thumbnails: {
      showThumbnails: false,
    },
  };

  const ShowSpecAvailQuan = () => {
    if (specAvailQuan === -1) {
      return <></>;
    } else {
      return (
        <div
          className={`w-50 mb-2 mt-3 ml-2 ${styles.marketDeliveryDetailsTitle}`}
        >
          {t("market.available")}: {specAvailQuan}
        </div>
      );
    }
  };

  const ShowThumbSlider = () => {
    if (!slidePhotos) {
      return;
    }
    if (slidePhotos.length > 1) {
      const slide2show = Math.min(slidePhotos.length, 3);
      return (
        <div className="thumbnail-slider-wrap">
          <Slider
            {...settingsThumbs}
            slidesToShow={slide2show}
            asNavFor={nav1}
            ref={(slider) => setSlider2(slider)}
          >
            {slidePhotos}
          </Slider>
        </div>
      );
    }
  };

  if (!topic || !market || !marketOptions || isLoading) {
    return <LoadingScreen />;
  } else {
    //Main Return
    return (
      <>
        {/* Navbar */}
        <ShowNavBar
          topicLinkID={topicLinkID}
          isMarket={true}
          activeKey="home"
        />
        {/* <Navbar bg="white">
      <Navbar.Brand href="/">
        <img
          src={KalinkLogo}
          width="2rem"
          height="2rem"
          className="d-inline-block align-center bg-white"
          alt="Kalink"
        />
      </Navbar.Brand>
      <Nav className="mr-auto">
        <Nav.Link href="/">Home</Nav.Link>
      </Nav>
      <div className="mr-sm-2">
        <Button
          variant="outline-dark"
          className={`border-1`}
          onClick={handleGo2Cart}
        >
          <span>Cart</span>
          <FiShoppingCart className={`${styles.marketCartBtn}`} />
        </Button>
      </div>
    </Navbar> */}
        {showAddCartModal()}
        <Container className="pl-4 pr-4 pt-3 pb-5">
          <Row>
            {/* Carousel */}

            {/*<Carousel>{GetMktPhotos(market)}</Carousel>*/}

            <Col xs={12} sm={12} md={5} lg={6} xl={6} className="bg-white">
              <SRLWrapper options={lightboxOptions}>
                <Slider
                  {...settingsMain}
                  asNavFor={nav2}
                  ref={(slider) => setSlider1(slider)}
                >
                  {slidePhotos}
                </Slider>
              </SRLWrapper>
              {ShowThumbSlider()}
              {/* <div>{GetMktPhotos(market)}</div>  */}
            </Col>

            <Col xs={12} sm={12} md={7} lg={6} xl={6}>
              {/* Price */}
              <Row className={styles.marketPrice}>
                {market.marketCurrency} {market.marketDisplayPrice}
              </Row>

              {/* Title */}
              <Row className={styles.marketTitle}>{market.marketTitle}</Row>

              {/* Tags */}
              <Row>{getMktTags(market)}</Row>

              {/* Desc */}
              <Row style={{ fontSize: "1em" }} className="marketDescText2">
                {market.marketDesc}
              </Row>

              {/* Specs */}
              <Row className={`mt-3`} ref={scrollRef}>
                {LayoutMktSpecs()}
              </Row>

              <div className="w-100">
                <ShowSpecAlert />
              </div>
              <div className="w-100">
                <ShowOOSAlert />
              </div>

              {/* Delivery */}
              <Row>{LayoutDelivery()}</Row>

              {/* Payment */}
              <Row>{LayoutPayment()}</Row>

              {/* InputNumber for quantity */}
              <Row>
                <ShowSpecAvailQuan />

                <div className="w-50 mb-2 mt-3 card">
                  <InputGroup>
                    {/*Decrement*/}
                    <InputGroup.Prepend>
                      <Button variant="light" onClick={onClickQuanDown}>
                        <IoRemove className={styles.inputControlBtn} />
                      </Button>
                    </InputGroup.Prepend>

                    {/* TODO: onChange */}
                    <FormControl
                      className="text-center border-0 font-weight-bolder"
                      readOnly={true}
                      value={orderQuan}
                    />

                    {/*Increment*/}
                    <InputGroup.Append>
                      <Button variant="light" onClick={onClickQuanUp}>
                        <IoAdd className={styles.inputControlBtn} />
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </div>

                <div className="w-100">
                  <ShowCounterAlert />
                </div>
              </Row>

              {/* Add Cart Btn */}
              <Row>
                <Button
                  variant="success"
                  className={`w-100 mt-3 ${styles.addCartBtn}`}
                  onClick={onClickAdd2Cart}
                >
                  {t("market.add2cart")}
                </Button>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
};

export default TopicMarket;

// const getSpecT2s = (specT1: MarketSpecT1) => {
//   const specT2s = specT1.specT2s;
//   return specT2s.map((specT2, index) => (
//     <ToggleButton
//       value={index}
//       className="roundedCorners10"
//       variant="light"
//       size="sm"
//     >
//       {specT2.specDetail}
//     </ToggleButton>
//   ));
// };

// return (
//   <div>
//     <div className="label mt-2">{specT1.specTitle}</div>
//     <ToggleButtonGroup
//       type="radio"
//       value={value}
//       name={specT1.specTitle}
//       onChange={handleChange}
//     >
//       {getSpecT2s(specT1)}
//     </ToggleButtonGroup>
//   </div>
// );

// Rect Bootstrap Carousel, not using becuase not much functions. Use "Slick Carousel" instead
// const GetMktPhotos = (market: MarketData) => {
//   if (market.marketPhotos && !_.isEmpty(market.marketPhotos)) {
//     const photos = Object.values(market.marketPhotos);
//
//     return photos.map((photo) => (
//       <Carousel.Item>
//         {console.log("photo is", photo.photoUrl)}
//         <Image className="mx-auto" src={photo.photoUrl} rounded />
//       </Carousel.Item>
//     ));
//   }
// };
