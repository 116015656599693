import React, { useEffect } from "react";
import { Row, Col, ListGroup, Image, Form, Card } from "react-bootstrap";
import { SRLWrapper } from "simple-react-lightbox";
import { run as runHolder } from "holderjs/holder";

import _ from "lodash";

import styles from "src/marketStyle.module.scss";
import { MarketCartPlaceholder } from "../holderImg";

import { useFormikContext } from "formik";
import {
  MarketSpec,
  MarketOrderSpec,
  FormFields,
  PhotoData,
} from "src/types/typings";
import { useTranslation } from "react-i18next";

import {
  MakeSelectCart,
  makeSelectMarket,
  MakeSelectTopicWithTopicID,
  MakeSelectMarketTotal,
} from "src/components/topics/marketCart";
import { isConstructorDeclaration } from "typescript";

const ProductDetails = (props: { topicID: string }) => {
  const topicID = props.topicID;
  //All the selSpecs
  const { values } = useFormikContext<FormFields>();
  const { t, i18n } = useTranslation();

  const selSpecs = MakeSelectCart(topicID);

  //const ShowOrderSpecs = (selSpecs: Record<string, MarketSpec[]>) => {
  const selTopicIDs = Object.keys(selSpecs);

  const lightboxOptions = {
    settings: {
      lightboxTransitionSpeed: 0.1,
    },
    buttons: {
      showThumbnailsButton: false,
      showAutoplayButton: false,
      showDownloadButton: false,
    },
    thumbnails: {
      showThumbnails: true,
    },
  };

  useEffect(() => {
    //placeholder
    runHolder("image-class-name");
  }, []);

  //   useEffect(() => {
  //

  //     selTopicIDs.map((topicID) => {
  //       const selTopicSpecs = selSpecs[topicID];
  //
  //       mapSpecOrder(selTopicSpecs, values);
  //     });
  //   }, [selTopicIDs]);

  //NOTE: At this point we only show one topic. i.e. only allow one topic to check out each time

  return (
    <>
      {selTopicIDs.map((topicID) => {
        const selTopicSpecs = selSpecs[topicID];
        mapSpecOrder(selTopicSpecs, values);
        return (
          <Col xs="12" key={topicID}>
            <Card className={`bg-light ${styles.cartContainer}`} key={topicID}>
              <ListGroup variant="flush">
                {/*Header*/}
                <ShowTopicHeader topicID={topicID} />
                {/*Note Component form is needed here
              https://medium.com/@jonchurch/how-to-fix-react-error-rendered-fewer-hooks-than-expected-e6a378985d3c#:~:text=First%2C%20double%20check%20that%20you,fewer%20hooks%20than%20expected%20error. */}
                <SRLWrapper options={lightboxOptions}>
                  <ShowTopicContent selTopicSpecs={selTopicSpecs} />
                </SRLWrapper>
              </ListGroup>
            </Card>
          </Col>
        );
      })}
    </>
  );
};

//className={`d-flex justify-content-between`}
const ShowTopicHeader = ({ topicID }) => {
  const { values } = useFormikContext<FormFields>();
  const { t, i18n } = useTranslation();
  const topic = MakeSelectTopicWithTopicID({ topicID: topicID });

  const tot = MakeSelectMarketTotal({
    topicID: topicID,
  });
  const totString =
    `${t("market.total")}` + ": " + tot["cur"] + " " + tot["totPrice"];

  const totProductPrice = tot["totPrice"];
  const cur = tot["cur"];

  values.orderProductPrice = tot["totPrice"];
  values.orderCur = cur;

  return (
    <Card.Header>
      <Row>
        <Col
          xs={12}
          sm={12}
          md={7}
          lg={7}
          xl={7}
          className={`${styles.marketTitle}`}
        >
          {t("market.title")}: {topic.topicTitle}
        </Col>

        <Col
          xs={12}
          sm={12}
          md={5}
          lg={5}
          xl={5}
          className={`d-flex flex-column align-items-sm-start align-items-md-end ${styles.marketTitle}`}
        >
          {totString}
        </Col>
      </Row>
    </Card.Header>
  );
};

const mapSpecOrder = (cartSpecs: MarketSpec[], values: FormFields) => {
  if (!cartSpecs) {
    return;
  }
  cartSpecs.map((cartSpec) => {
    const market = makeSelectMarket(cartSpec.specMarketID);

    //NOTE: under the assumption that there's only one topic in this page
    //We have to check for dupes every time because field input will cause all components to re-render!
    if (!values.orderMktIDs.includes(cartSpec.specMarketID)) {
      values.orderMktIDs.push(cartSpec.specMarketID);
    }

    let specOrderLimit = -1;
    if (market.marketOrderLimit !== -1) {
      if (cartSpec.specStock == -1) {
        //unlimited stock
        specOrderLimit = market.marketOrderLimit;
      } else {
        specOrderLimit = Math.min(market.marketOrderLimit, cartSpec.specStock);
      }
    } else {
      specOrderLimit = cartSpec.specStock;
    }

    //NOTE: Use first market photo if specPhoto not found
    let specPhoto = cartSpec.specPhoto;
    if (!specPhoto || specPhoto === "") {
      if (market.marketDisplayPhoto) {
        specPhoto = market.marketDisplayPhoto;
      }
    }

    let orderSpec: MarketOrderSpec = {
      specID: cartSpec.specID,
      specMktTitle: market.marketTitle,
      specMktID: market.marketID,
      specDesc: cartSpec.specDesc,
      specPrice: cartSpec.specPrice,
      specCurrency: market.marketCurrency,
      specTopicID: market.topicID,
      specStock: cartSpec.specStock,
      specSKU: cartSpec.specSKU,
      specOrderLimit: specOrderLimit, //Note this is computed
      specPhoto: specPhoto,
      specQuantity: cartSpec.orderQuantity,
      specT2IDs: cartSpec.specT2IDs,
      specOrderID: "",
      orderID: "",
    };

    //NOTE: We have to check for dupes every time because field input will cause all components to re-render!
    if (
      values.orderSpecs.filter((s) => s.specID == orderSpec.specID).length == 0
    ) {
      values.orderSpecs.push(orderSpec);
    }
  });
};

// NOTE[epic=snippet] an example of mapping in component form
const ShowTopicContent = (props: {
  selTopicSpecs: MarketSpec[];
}): JSX.Element => {
  return (
    <>
      {props.selTopicSpecs.map((spec) => {
        const market = makeSelectMarket(spec.specMarketID);

        //return <ShowSpec selSpec={spec} market={market} />;
        return (
          <ShowSpecCheckout selSpec={spec} market={market} key={spec.specID} />
        );
      })}
    </>
  );
};

const ShowSpecCheckout = ({ selSpec, market }) => {
  const { t, i18n } = useTranslation();

  const showQuantity = () => {
    return (
      <Col
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={`d-flex flex-column justify-content-between`}
      >
        <Row
          className={`d-flex flex-column align-items-sm-start align-items-md-end ${styles.cartSpecQuanChkout}`}
        >
          {t("market.quantity")}: {selSpec.orderQuantity}
        </Row>
        <Row
          className={`mt-2 d-flex flex-column align-items-sm-start align-items-md-end ${styles.cartSpecPrice}`}
        >
          {t("market.subtotal")}: {market.marketCurrency}{" "}
          {selSpec.specPrice * selSpec.orderQuantity}
        </Row>
      </Col>
    );
  };

  const ShowSpecPhoto = () => {
    if (selSpec.specPhoto && selSpec.specPhoto.photoContentURL !== "") {
      return (
        <a href={selSpec.specPhoto.photoContentURL}>
          <MarketCartPlaceholder
            url={selSpec.specPhoto.photoContentURL}
            className={`d-inline-block align-center bg-white image-class-name`}
          />
        </a>
        //</SRLWrapper>
      );
    } else if (
      //Shouldn't fall into this case as draftPhoto already got inserted into 1 spec only cases
      //This is only a safeguard measure
      market.marketDisplayPhotoURL &&
      market.marketDisplayPhotoURL != ""
    ) {
      return (
        <MarketCartPlaceholder
          url={market.marketDisplayPhotoURL}
          className={`d-inline-block align-center bg-white`}
        />
      );
    } else {
      //No photo
      return (
        <Image
          src="holder.js/200x200?text=Empty"
          className={`d-inline-block align-center bg-white image-class-name`}
          alt="Photo Not Found"
        />
      );
    }
  };

  return (
    <ListGroup.Item>
      <Row xs={12} sm={12} md={12} lg={12} xl={12}>
        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
          {ShowSpecPhoto()}
        </Col>
        <Col xs={9} sm={9} md={9} lg={9} xl={9}>
          <Row className="h-100">
            <Col xs={12} sm={12} md={7} lg={7} xl={7}>
              <Row className={`${styles.cartSpecTitle}`}>
                {market.marketTitle ?? "No Title"}
              </Row>
              <Row className={`${styles.cartSpecDesc}`}>{selSpec.specDesc}</Row>
              <Row className={`mt-2 ${styles.cartSpecUnitPrice}`}>
                {market.marketCurrency} {selSpec.specPrice}
              </Row>
            </Col>

            {/* InputNumber for quantity */}
            {showQuantity()}
          </Row>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default ProductDetails;
