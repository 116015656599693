import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { run as runHolder } from "holderjs/holder";
import {
  Container,
  Navbar,
  Nav,
  Form,
  Row,
  Col,
  Button,
  ListGroup,
  Card,
  Image,
  InputGroup,
  Modal,
} from "react-bootstrap";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SignInScreen from "src/firebase/firebaseui";
import _ from "lodash";
import styles from "src/marketStyle.module.scss";
import {
  selectMarketOptions,
  selectTopicOrderSpecs,
} from "src/selectors/marketSelector";
import { selectTopic } from "src/selectors/topicSelector";
import { AppState, useAppDispatch, AppDispatch } from "src/redux/store";
import {
  TopicData,
  MarketSpec,
  MarketOrder,
  MarketOptions,
  MarketOrderSpec,
  marketOrderStatus,
  marketPaymentStatus,
  FormFields,
  marketDeliveryOption,
} from "src/types/typings";
import { useSelector } from "react-redux";
import ShowNavBar from "src/components/navBar";
import { useTranslation } from "react-i18next";
import CheckoutForm from "./marketChkoutForm";
import { uploadMarketOrder } from "src/firebase/firebaseMarkets";
import { fetchMarketOrderSuccess } from "src/redux/reducers/marketOrderSlice";
import { fetchMarketSpecSuccess } from "src/redux/reducers/marketSpecSlice";
import {
  fireFetchSpecInventory,
  fireFetchMarketOrderQuota,
} from "src/firebase/firebaseMarkets";
import LoadingScreen from "src/components/loading";
import { fetchTopicMarketsThunk } from "src/redux/reducers/marketSlice";

const MakeSelectMarketOptions = ({ topicID }) => {
  const selMarketOptions: Partial<MarketOptions> | undefined = useSelector(
    (state: AppState) => {
      return selectMarketOptions(state, topicID);
    }
  );
  return selMarketOptions;
};

const MakeSelectTopicCart = ({ topicID }) => {
  const selCartSpecs: MarketSpec[] | undefined = useSelector(
    (state: AppState) => {
      return selectTopicOrderSpecs(state, topicID);
    }
  );
  return selCartSpecs;
};

const MakeSelectTopic = ({ topicLinkID }) => {
  const topic: TopicData | undefined = useSelector((state: AppState) =>
    selectTopic(state, topicLinkID)
  );
  return topic;
};

//SECTION: MAIN FUNCTION
const MarketCheckout = () => {
  const { topicLinkID } = useParams();

  //const [selLockerLoc, setSelLockerLoc] = useState<string>(""); //The lockerloc is the last piece of data we want, should the user choose locker
  const [isSubmitOrderModalShown, setIsSubmitOrderModalShown] = useState(false);
  const [isOOSModalShown, setIsOOSModalShown] = useState(false); //out of stock
  const [isOOSMktModalShown, setIsOOSMktModalShown] = useState(false); //exceeds market quota
  const [isLoading, setIsLoading] = useState(false);
  const [isSigninModalShown, setIsSigninModalShown] = useState(false);

  const handleSubmitOrderModalClose = () => setIsSubmitOrderModalShown(false);
  const handleOOSModalClose = () => setIsOOSModalShown(false);
  const handleOOSMktModalClose = () => setIsOOSMktModalShown(false);
  const handleSigninModalClose = () => setIsSigninModalShown(false);

  const [submitValues, setSubmitValues] = useState<FormFields>();
  const [oosSpecIDnInvs, setOOSSpecIDnInvs] = useState<Record<string, number>>(
    {}
  );
  const [oosMktIDnQuota, setOOSMktIDnQuota] = useState<Record<string, number>>(
    {}
  );
  //let oosSpecIDnInvs: Record<string, number> = {};

  const { t, i18n } = useTranslation();

  const topic = MakeSelectTopic({
    topicLinkID: topicLinkID,
  });
  let marketOptions: Partial<MarketOptions> = {};
  let orderSpecs: MarketOrderSpec[] = [];
  let orderMktIDs: string[] = [];
  let curTopicCartSpecs: MarketSpec[] = [];

  if (topic && !_.isEmpty(topic) && topic.topicMarketsCount > 0) {
    const topicID = topic.topicID;
    marketOptions = MakeSelectMarketOptions({ topicID: topicID }) ?? {};
    curTopicCartSpecs = MakeSelectTopicCart({
      topicID: topic.topicID,
    });
  }

  const isLoggedIn = useSelector((state: AppState) => {
    return state.auth.isLoggedIn;
  });

  const appDispatch = useAppDispatch();

  useEffect(() => {
    //placeholder
    runHolder("image-class-name");
  }, [isLoading]);

  //If no useCallback, state update will cause the form to re-render and erased all inputed values
  //https://dmitripavlutin.com/dont-overuse-react-usecallback/
  const onClickSubmitOrder = useCallback(
    (values: FormFields) => {
      //Process order submission

      setSubmitValues(values);

      //Check again if user is logged in first
      if (!isLoggedIn) {
        setIsSigninModalShown(true);
      } else {
        setIsSubmitOrderModalShown(true);
      }
    },
    [topicLinkID, isLoggedIn]
  );

  //Final reconfirm button when modal shown, form the final MarketOrder object
  const createMarketOrder = () => {
    if (!submitValues) {
      return;
    }
    let marketOrder: Partial<MarketOrder> = {};

    if (topic) {
      marketOrder.topicID = topic.topicID;
      marketOrder.topicLinkID = topic.topicLinkID;
      marketOrder.orderSellerID = topic.topicCreatorID;
    }

    if (submitValues.orderName && submitValues.orderEmail) {
      marketOrder.orderName = submitValues.orderName;
      marketOrder.orderEmail = submitValues.orderEmail;
      marketOrder.orderPhone = submitValues.orderPhone;
      marketOrder.orderAddress1 = submitValues.orderAddress1;
      marketOrder.orderAddress2 = submitValues.orderAddress2;
      marketOrder.orderEFLockerCode = submitValues.orderEFLockerCode;
      marketOrder.orderCurrency = submitValues.orderCur;
      marketOrder.orderDeliveryMethod = submitValues.orderDeliveryMethod;
      marketOrder.orderAdditionalMsg = submitValues.orderAdditionalMsg;
      marketOrder.orderPaymentStatus = marketPaymentStatus.UNPAID;
      marketOrder.orderProductPrice = submitValues.orderProductPrice;
      marketOrder.orderShippingFee = submitValues.orderShippingFee;
      marketOrder.orderFinalPrice = submitValues.orderFinalPrice;
      marketOrder.orderPickupDetails = submitValues.orderPickupDetails;
      marketOrder.orderSpecs = submitValues.orderSpecs;
      marketOrder.marketIDs = submitValues.orderMktIDs;
      marketOrder.orderCourierPayment = submitValues.orderCourierPaymentTxt;
      marketOrder.orderStatus = marketOrderStatus.SELLERUNCONFIRMED;
    }

    return marketOrder;
  };

  const history = useHistory();

  const handleGo2Receipt = (orderID: string) => {
    //Make sure user is logged in
    setIsSubmitOrderModalShown(false);
    setIsLoading(false);
    history.push({
      pathname: "/mr",
      search: `?id1=${topicLinkID}&id2=${orderID}`,
    });
  };

  const handleGo2Cart = () => {
    setIsOOSModalShown(false);
    history.push(`/c/${topicLinkID}`);
  };

  //Final reconfirm button when modal shown
  const handleSubmitOrder = async () => {
    setIsLoading(true);
    const pendingMarketOrder = createMarketOrder();
    if (pendingMarketOrder) {
      //check stock
      const { success, oosSpecIDnInvs, oosMktIDnQuota } =
        await isValidatedOrderInventory(pendingMarketOrder);

      if (!success) {
        //Out of stock or exceeds market quota, display error message, and update redux store
        if (topic && topic.topicID) {
          //Out of stock found or exceeds market quota, market info is not updated, update topic markets
          appDispatch(fetchTopicMarketsThunk(topic.topicID));
        }

        if (!_.isEmpty(oosSpecIDnInvs)) {
          //Out of stock
          setOOSSpecIDnInvs(oosSpecIDnInvs);
          //Need to hide the submit order modal
          handleSubmitOrderModalClose();
          setIsOOSModalShown(true);
        } else if (!_.isEmpty(oosMktIDnQuota)) {
          //Exceeds market quota
          setOOSMktIDnQuota(oosMktIDnQuota);
          //Need to hide the submit order modal
          handleSubmitOrderModalClose();
          setIsOOSMktModalShown(true);
        }
      } else {
        //Passed validation, procced to upload order
        const newMarketOrder = await uploadMarketOrder(
          pendingMarketOrder,
          topic?.topicTitle ?? ""
        );
        if (newMarketOrder) {
          appDispatch(clearMarketCart(newMarketOrder, curTopicCartSpecs));
          appDispatch(fetchMarketOrderSuccess(newMarketOrder));
          //Finish market checkout, go to receipt page
          if (newMarketOrder.orderID) {
            handleGo2Receipt(newMarketOrder.orderID);
          }
        }
      }
    }
    setIsLoading(false);
  };

  const isValidatedOrderInventory = async (
    marketOrder: Partial<MarketOrder>
  ): Promise<{
    success: boolean;
    oosSpecIDnInvs: Record<string, number>;
    oosMktIDnQuota: Record<string, number>;
  }> => {
    const orderSpecs = marketOrder.orderSpecs;
    const topicID = marketOrder.topicID;
    const oosSpecs: Record<string, number> = {};
    const oosMarket: Record<string, number> = {}; //[mktID, mktOrderLimit]
    const quotaMarket: Record<string, number> = {}; //[mktID, specQuantity(add all)]
    if (!orderSpecs || !topicID) {
      return {
        success: false,
        oosSpecIDnInvs: oosSpecs,
        oosMktIDnQuota: oosMarket,
      };
    }

    await Promise.all(
      orderSpecs.map(async (orderSpec) => {
        const mktID = orderSpec.specMktID;
        const specID = orderSpec.specID;
        const mktOrderLimit = await fireFetchMarketOrderQuota(topicID, mktID);
        const specStock = await fireFetchSpecInventory(topicID, mktID, specID);
        //const spec
        if (specStock !== null) {
          if (
            specStock !== null &&
            specStock !== undefined &&
            specStock >= 0 &&
            specStock < orderSpec.specQuantity
          ) {
            oosSpecs[specID] = specStock;
          }
        }

        if (mktOrderLimit !== null && mktOrderLimit > -1) {
          const curOrderNum = quotaMarket[mktID] ?? 0;
          const newOrderNum = curOrderNum + orderSpec.specQuantity;
          if (newOrderNum > mktOrderLimit) {
            oosMarket[mktID] = mktOrderLimit;
          } else {
            quotaMarket[mktID] = newOrderNum;
          }
        }
      })
    );

    if (_.isEmpty(oosSpecs) && _.isEmpty(oosMarket)) {
      return {
        //Passed validation
        success: true,
        oosSpecIDnInvs: oosSpecs,
        oosMktIDnQuota: oosMarket,
      };
    } else {
      return {
        //Failed validation
        success: false,
        oosSpecIDnInvs: oosSpecs,
        oosMktIDnQuota: oosMarket,
      };
    }
  };

  const ShowMktOOSModal = () => {
    const mktIDs = Object.keys(oosMktIDnQuota);

    return (
      <Modal
        show={isOOSMktModalShown}
        centered={true}
        animation={true}
        onHide={handleOOSMktModalClose}
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              ...styles,
              fontSize: 24,
              fontWeight: "bolder",
            }}
          >
            {t("market.chkoutOOSMkt")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={`${styles.orderOOSAlert}`}>
          <p>{t("market.chkoutOOSMktDetails")}</p>
          {mktIDs.map((mktID) => {
            const specs = submitValues?.orderSpecs.filter(
              (s) => s.specMktID === mktID
            );
            if (specs) {
              return (
                <li key={mktID}>
                  {specs.map((spec) => {
                    return (
                      <>
                        {spec.specMktTitle} {spec.specDesc} {`\n`}
                        {t("market.chkoutOOSOrder")} :{spec.specQuantity} {`\n`}
                      </>
                    );
                  })}
                  {`\n`}
                  <b>
                    {t("market.chkoutOOSMktQuota")} :{oosMktIDnQuota[mktID]}
                  </b>
                </li>
              );
            }
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button
            block={true}
            variant="success"
            onClick={handleGo2Cart}
            className={`${styles.emptyCart__btn}`}
          >
            {t("market.back2Cart")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ShowOOSModal = () => {
    const specIDs = Object.keys(oosSpecIDnInvs);

    return (
      <Modal
        show={isOOSModalShown}
        centered={true}
        animation={true}
        onHide={handleOOSModalClose}
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              ...styles,
              fontSize: 24,
              fontWeight: "bolder",
            }}
          >
            {t("market.chkoutOOS")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={`${styles.orderOOSAlert}`}>
          <p>{t("market.chkoutOOSDetails")}</p>
          {specIDs.map((specID) => {
            const spec = submitValues?.orderSpecs.filter(
              (s) => s.specID === specID
            )[0];
            if (spec) {
              return (
                <li key={specID}>
                  {spec.specMktTitle} {spec.specDesc} {`\n`}
                  {t("market.chkoutOOSOrder")} :{spec.specQuantity} {`\n`}
                  {t("market.chkoutOOSStock")} :{oosSpecIDnInvs[specID]}{" "}
                </li>
              );
            }
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button
            block={true}
            variant="success"
            onClick={handleGo2Cart}
            className={`${styles.emptyCart__btn}`}
          >
            {t("market.back2Cart")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ShowSubmitOrderModal = () => {
    if (!submitValues) {
      return <></>;
    }

    return (
      <Modal
        show={isSubmitOrderModalShown}
        centered={true}
        animation={true}
        onHide={handleSubmitOrderModalClose}
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              ...styles,
              fontSize: 24,
              fontWeight: "bolder",
            }}
          >
            {t("market.chkoutSubmit")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{t("market.chkoutSubmitDetails")}</p>
          <p>{t("market.chkoutSubmitDetails2")}</p>
          <p>{submitValues.orderEmail}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button block={true} variant="success" onClick={handleSubmitOrder}>
            {t("market.chkoutSubmit2")}
          </Button>
          <Button
            block={true}
            variant="light"
            onClick={handleSubmitOrderModalClose}
          >
            {t("market.chkoutReviewOrder")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  //Needed because user may sign out in the process
  const ShowSigninModal = () => {
    return (
      <Modal
        show={isSigninModalShown}
        centered={true}
        animation={true}
        onHide={handleSigninModalClose}
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              ...styles,
              fontSize: 24,
              fontWeight: "bolder",
            }}
          >
            Sign-in
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <SignInScreen onHide={handleSigninModalClose} />
        </Modal.Body>
      </Modal>
    );
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!topic) {
    return <></>;
  }

  return (
    <>
      <ShowNavBar
        topicLinkID={topicLinkID}
        isMarket={true}
        activeKey="checkout"
      />
      <CheckoutForm
        onClickSubmitOrder={onClickSubmitOrder}
        marketOptions={marketOptions}
        topicID={topic.topicID}
      />
      <ShowSubmitOrderModal />
      <ShowOOSModal />
      <ShowMktOOSModal />
      <ShowSigninModal />
    </>
  );
};

export const clearMarketCart =
  (marketOrderData: Partial<MarketOrder>, curTopicCartSpecs: MarketSpec[]) =>
  (dispatch: AppDispatch) => {
    const orderSpecs = marketOrderData.orderSpecs;
    if (orderSpecs) {
      let deleteSpecs: MarketSpec[] = [];
      orderSpecs.map((spec) => {
        curTopicCartSpecs.map((curSpec) => {
          if (spec.specID === curSpec.specID) {
            deleteSpecs.push(curSpec);
          }
        });
      });

      if (deleteSpecs.length > 0) {
        deleteSpecs.map((deleteSpec) => {
          const newSpec: Partial<MarketSpec> = {};
          newSpec.specID = deleteSpec.specID;
          newSpec.orderQuantity = 0;
          dispatch(fetchMarketSpecSuccess(newSpec));
        });
      }
    }
  };

export default MarketCheckout;
